import { Bars3BottomRightIcon } from "@heroicons/react/24/solid";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Logo } from "../../assets";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navIcons } from "./Sidebar";
import WalletDisplay from "../../component/WalletDisplay";
import WalletModal from "../../component/WalletModal";
import { WalletConnection } from "../../service/WalletConnection";
import theme from "../../theme/Theme";

const Header = ({ isMobile, onDrawerToggle }) => {
  const location = useLocation();
  const [activeNav, setActiveNav] = useState("Home");
  const {
    connectedAddress,
    connectedWalletType,
    isModalOpen,
    handleConnectWalletPress,
    handleDisconnect,
    handleWalletConnect,
    setModalOpen,
  } = WalletConnection();

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveNav("Home");
    } else if (location.pathname === "/draw") {
      setActiveNav("Draw");
    }
  }, [location.pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#FFFFFF",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {isMobile && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onDrawerToggle}
            sx={{ mr: 2 }}
          >
            <Bars3BottomRightIcon color="#000000" width={24} />
          </IconButton>
        )}
        <Box
          component={"img"}
          src={Logo}
          sx={styles.logoStyle}
          alt="SWSS Wallets"
        />

        {!isMobile && (
          <Box sx={styles.nav}>
            {navIcons.map((navItem) => (
              <Link to={navItem.path} style={styles.navLinkStyle}>
                <Typography
                  sx={{
                    ...styles.navItem,
                    color:
                      activeNav === navItem.label
                        ? "#000000"
                        : theme.palette.primary.main,
                  }}
                >
                  {navItem.label}
                </Typography>
              </Link>
            ))}
          </Box>
        )}
        <WalletDisplay
          connectedAddress={connectedAddress}
          connectedWalletType={connectedWalletType}
          onConnect={handleConnectWalletPress}
          onDisconnect={handleDisconnect}
        />
        <WalletModal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          onWalletSelect={handleWalletConnect}
        />
      </Toolbar>
    </AppBar>
  );
};
export default Header;
const styles = {
  logoStyle: {
    height: {
      xs: "1.5em",
      md: "2em",
    },
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  navItem: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
    transition: "all 0.3s ease-in-out",
    textTransform: "uppercase",
    "&:hover": {
      color: "rgba(0, 0, 0, 1)",
      cursor: "pointer",
    },
  },
  navLinkStyle: {
    textDecoration: "none",
  },
};
