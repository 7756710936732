export const blockchainNetworks = {
    bscMainnet: {
        networkName: "Binance Smart Chain Mainnet",
        chainId: "56",
        ticker: "BNB",
        rpcUrl: "https://bsc-dataseed.binance.org/",
        explorerBaseUrl: "https://bscscan.com",
        iconUrl: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035",
        infuraUrl: "https://bsc-mainnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43", // need to change this
        slip44Index: "714"
    },
    bscTestnet: {
        networkName: "Binance Smart Chain Testnet",
        chainId: "97",
        ticker: "BNB",
        rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        explorerBaseUrl: "https://testnet.bscscan.com",
        iconUrl: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035",
        infuraUrl: "https://bsc-testnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43",
        slip44Index: "714"
    },
    ethereum: {
        networkName: "Ethereum Mainnet",
        chainId: "1",
        ticker: "ETH",
        rpcUrl: "https://mainnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43",
        explorerBaseUrl: "https://etherscan.io",
        iconUrl: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035",
        infuraUrl: "https://bsc-testnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43", // need to change this
        slip44Index: "60"
    },
    sepolia: {
        networkName: "Sepolia Testnet",
        chainId: "11155111",
        ticker: "ETH",
        rpcUrl: "https://sepolia.infura.io/v3/d54f436e4e374252ae4c2861ef787f43",
        explorerBaseUrl: "https://sepolia.etherscan.io",
        iconUrl: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035",
        infuraUrl: "https://bsc-testnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43",
        slip44Index: "11155111"
    },
    tron: {
        networkName: "Tron Mainnet",
        chainId: "1",
        ticker: "TRX",
        rpcUrl: "https://api.trongrid.io",
        explorerBaseUrl: "https://tronscan.org",
        iconUrl: "https://cryptologos.cc/logos/tron-trx-logo.svg?v=035",
        infuraUrl: "https://bsc-testnet.infura.io/v3/d54f436e4e374252ae4c2861ef787f43", // need to change
        slip44Index: "195"
    },
    swssMainnet: {
        networkName: "SWSS Mainnet",
        chainId: "789",
        ticker: "SWSS",
        rpcUrl: "https://rpc.swss.io",
        explorerBaseUrl: "https://explorer.swss.io",
        iconUrl: "https://swss.io/images/SWSS-CHAIN-p-500.png",
        infuraUrl: "https://rpc.swss.io",
        slip44Index: "789"
    },
}
