import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { drawerWidth } from "../../utils/common";
import {
  ChatBubbleBottomCenterTextIcon,
  FaceSmileIcon,
  HomeIcon,
  TicketIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineTicket } from "react-icons/hi";
import { HiOutlineTrophy } from "react-icons/hi2";
import { FaSlideshare } from "react-icons/fa";
import { IoReceiptOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const navIcons = [
  {
    icon: (
      <HomeIcon
        style={{ width: 24, height: 24, color: "rgba(46, 46, 48, 1)" }}
      />
    ),
    label: "Home",
    path: "/",
  },
  {
    icon: (
      <TicketIcon
        style={{ width: 24, height: 24, color: "rgba(46, 46, 48, 1)" }}
      />
    ),
    label: "Draws",
    path: "/draw",
  },
  {
    icon: (
      <UserGroupIcon
        style={{ width: 24, height: 24, color: "rgba(46, 46, 48, 1)" }}
      />
    ),
    label: "Referral",
    path: "/referrals",
  },
  {
    icon: (
      <FaceSmileIcon
        style={{ width: 24, height: 24, color: "rgba(46, 46, 48, 1)" }}
      />
    ),
    label: "Testimonials",
    path: "/testimonials",
  },
  {
    icon: (
      <ChatBubbleBottomCenterTextIcon
        style={{ width: 24, height: 24, color: "rgba(46, 46, 48, 1)" }}
      />
    ),
    label: "Contact us",
    path: "/contact-us",
  },
];
export const sideBarMenu = [
  // {
  //   icon: (
  //     <TbLayoutDashboard width={28} height={28} color={"rgba(46, 46, 48, 1)"} />
  //   ),
  //   label: "Dashboard",
  //   path: "/dashboard",
  // },
  {
    icon: <HiOutlineTicket width={28} height={28} />,
    label: "My Tickets",
    path: "tickets",
  },
  {
    icon: <FaSlideshare width={28} height={28} />,
    label: "Referrals",
    path: "referrals",
  },
  {
    icon: <IoReceiptOutline width={28} height={28} />,
    label: "Transactions",
    path: "transactions",
  },
  {
    icon: <HiOutlineTrophy width={28} height={28} />,
    label: "My Winnings",
    path: "winnings",
  },
  // {
  //   icon: (
  //     <BsGearWideConnected
  //       width={28}
  //       height={28}
  //       color={"rgba(46, 46, 48, 1)"}
  //     />
  //   ),
  //   label: "Settings",
  //   path: "settings",
  // },
  {
    icon: <AiOutlineLogout width={28} height={28} />,
    label: "Logout",
  },
];

const Sidebar = ({ isMobile, isOpen, onClose, onDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeNav, setActiveNav] = useState("My Tickets");
  const [logoutConfirmDialog, showLogoutConfirmDialog] = useState(false);

  useEffect(() => {
    if (location.pathname === "/dashboard/tickets") {
      setActiveNav("My Tickets");
    } else if (location.pathname === "/dashboard/referrals") {
      setActiveNav("Referrals");
    } else if (location.pathname === "/dashboard/transactions") {
      setActiveNav("Transactions");
    } else if (location.pathname === "/dashboard/winnings") {
      setActiveNav("My Winnings");
    }
  }, [location.pathname]);

  const handleDisconnect = async () => {
    try {
      const walletType = Cookies.get("connectedWalletType");
      if (walletType.trim().toLowerCase() === "metamask") {
        // Request MetaMask to revoke permissions
        await window.ethereum.request({
          method: "wallet_revokePermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        // console.log("MetaMask permissions successfully revoked.");
      }
    } catch (error) {
      console.error("Error revoking MetaMask permissions:", error);
    }
    Cookies.remove("connectedAddress");
    Cookies.remove("connectedWalletType");
    navigate("/");
  };

  return (
    <Box component="nav" aril-label="sidebar navigation">
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={onClose}
        ModalProps={{ keepMounted: true }}
        sx={{
          backgroundColor: "#FFFFFF",
          display: { xs: "block", sm: "none" },
          "& .MuiPaper-root": {
            borderRight: "none", // Removes the right border
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            paddingTop: "3em",
          },
        }}
      >
        <List>
          {sideBarMenu.map((item, index) => (
            <ListItem
              key={item.label}
              sx={{
                fontSize: 16,
                color:
                  activeNav === item.label
                    ? "rgba(255,255,255,1)"
                    : "rgba(46, 46, 48, 1)",
                backgroundColor:
                  activeNav === item.label
                    ? "rgba(191, 21, 34, 1)"
                    : "transparent",
              }}
              onClick={() => {
                if (item.label === "Logout") {
                  showLogoutConfirmDialog(true);
                } else {
                  navigate(item.path);
                }
                onDrawerToggle(false);
              }}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                sx={{
                  color:
                    activeNav === item.label
                      ? "rgba(255,255,255,1)"
                      : "rgba(46, 46, 48, 1)",
                }}
              >
                {item.icon}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "4vw", lg: "1.2vw" },
                    marginLeft: 1,
                  }}
                >
                  {item.label}
                </Typography>
              </Stack>
            </ListItem>
          ))}
          <Divider sx={{ my: 2 }} />
          {navIcons.map((item, index) => (
            <ListItem
              button
              key={item.label}
              sx={{
                fontSize: 16,
                color:
                  activeNav === item.label
                    ? "rgba(255,255,255,1)"
                    : "rgba(46, 46, 48, 1)",
                backgroundColor:
                  activeNav === item.label
                    ? "rgba(191, 21, 34, 1)"
                    : "transparent",
              }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <Stack direction="row" alignItems={"center"}>
                {item.icon}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "4vw", lg: "1.2vw" },
                    marginLeft: 1,
                  }}
                >
                  {item.label}
                </Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Desktop Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          backgroundColor: "#FFFFFF",
          display: { xs: "none", sm: "block" },
          "& .MuiPaper-root": {
            borderRight: "none", // Removes the right border
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            paddingTop: "3em",
            margin: 1,
          },
        }}
        open
      >
        {sideBarMenu.map((item, index) => (
          <ListItem
            key={item.label}
            sx={{
              fontSize: 16,
              width: drawerWidth - 4,
              borderRadius: 10,
              color:
                activeNav === item.label
                  ? "rgba(255,255,255,1)"
                  : "rgba(46, 46, 48, 1)",
              backgroundColor:
                activeNav === item.label
                  ? "rgba(191, 21, 34, 1)"
                  : "transparent",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "rgba(191, 21, 34, 0.8)",
                color: "rgba(255, 255, 255, 1)",
              },
              padding: "5px 10px",
              marginBottom: "5px",
            }}
            onClick={() => {
              if (item.label === "Logout") {
                showLogoutConfirmDialog(true);
              } else {
                navigate(item.path);
              }
            }}
          >
            <ListItemIcon
              sx={{
                color:
                  activeNav === item.label
                    ? "rgba(255,255,255,1)"
                    : "rgba(46, 46, 48, 1)",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              sx={{
                "& .MuiListItemText-primary": {
                  fontSize: "0.9rem",
                  fontWeight: 600, // custom font size for primary text
                },
              }}
            />
          </ListItem>
        ))}
      </Drawer>
      <Dialog
        open={logoutConfirmDialog}
        onClose={() => showLogoutConfirmDialog(false)}
      >
        <DialogContent>
          <Typography sx={styles.dialogTitle}>Are you sure?</Typography>
          <Typography sx={styles.dialogDescription}>
            Logging out will disconnect your wallet.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showLogoutConfirmDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleDisconnect}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default Sidebar;

const styles = {
  dialogTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
  },
  dialogDescription: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(46, 46, 48, 1)",
    textAlign: "center",
    marginTop: "0.5em",
  },
};
