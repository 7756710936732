import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
} from "@mui/material";
import theme from "../../theme/Theme";

const TermsConditionPage = () => {
  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Card sx={styles.cardStyle}>
        <CardHeader title={"Terms & Conditions"} />
        <CardContent>
          <List style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
            <ListItem style={{ ...styles.headingStyle, display: "list-item" }}>
              Eligibility
              <List style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
                <ListItem
                  style={{ ...styles.paraStyles, display: "list-item" }}
                >
                  The lucky draw is open to all participants aged [18] or older.
                </ListItem>
                <ListItem
                  style={{ ...styles.paraStyles, display: "list-item" }}
                >
                  Employees, affiliates, and family members of
                  [Company/Organizer Name] are not eligible to participate.
                </ListItem>
                <ListItem
                  style={{ ...styles.paraStyles, display: "list-item" }}
                >
                  Participation is void where prohibited by law.
                </ListItem>
              </List>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};
export default TermsConditionPage;
const styles = {
  cardStyle: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
  headingStyle: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 600,
  },
  paraStyles: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 500,
  },
};
