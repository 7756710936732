import ScrollToTop from "./hooks/ScrollToTop";
import AuthGuard from "./layout/AuthGuard";
import DashboardLayout from "./layout/dashboardLayout/DashboardLayout";
import ContactUs from "./pages/contactus/ContactUs";
import DashboardPage from "./pages/dashboard/DashboardPage";
import MyTicketsPage from "./pages/dashboard/mytickets/MyTicketsPage";
import MyWinningsPage from "./pages/dashboard/mywinnings/MyWinningsPage";
import MyReferralsPage from "./pages/dashboard/referrals/MyReferralsPage";
import MySettingsPage from "./pages/dashboard/settings/MySettingsPage";
import MyTransactionsPage from "./pages/dashboard/transactions/MyTransactionsPage";
import DisclaimerPage from "./pages/disclaimer/DisclaimerPage";
import DrawPage from "./pages/draw/Draw";
import DrawDetails from "./pages/draw/drawdetails/DrawDetails";
import Home from "./pages/home/Home";
import LeaderboardPage from "./pages/leaderboard/LeaderboardPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";
import ReferralsPage from "./pages/referrals/ReferralsPage";
import TermsConditionPage from "./pages/termsCondition/TermsConditionPage";
import TestimonialsPage from "./pages/testimonials/TestimonialsPage";
import Cookies from "js-cookie";

const { Routes, Route, BrowserRouter, Navigate } = require("react-router-dom");
const { default: MainLayout } = require("./layout/MainLayout");

const AppRoutes = () => {
  // let isLoggedIn = false;

  // const validateWalletConnection = async () => {
  //   console.log("validateWalletConnection");
  //   const storedAddress = Cookies.get("connectedAddress");
  //   const storedWalletType = Cookies.get("connectedWalletType");

  //   if (storedAddress && storedWalletType === "Metamask") {
  //     try {
  //       if (window.ethereum?.isMetaMask) {
  //         const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  //         if (accounts.length > 0 && accounts[0].toLowerCase() === storedAddress.toLowerCase()) {
  //           isLoggedIn = true;
  //         } else {
  //           await window.ethereum.request({ method: "eth_requestAccounts" });
  //         }
  //       } else {
  //         await window.ethereum.request({ method: "eth_requestAccounts" });
  //       }
  //     } catch (error) {
  //       console.error("Error validating wallet connection:", error);
  //     }
  //   }
  // };
  // validateWalletConnection();

  const storedAddress = Cookies.get("connectedAddress");
  const storedWalletType = Cookies.get("connectedWalletType");
  const isLoggedIn =
    storedAddress !== undefined && storedWalletType !== undefined;

  {
    /* <Route
          path="/dashboard"
          element={
            <AuthGuard isLoggedIn={isLoggedIn}>
              <DashboardLayout />
            </AuthGuard>
          }
        > */
  }
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/referrals" element={<ReferralsPage />} />
          <Route path="/draw" element={<DrawPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsConditionPage />}
          />
          <Route path="/draw/:id" element={<DrawDetails />} />
        </Route>

        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<MyTicketsPage />} />
          <Route path="tickets" element={<MyTicketsPage />} />
          <Route path="referrals" element={<MyReferralsPage />} />
          <Route path="transactions" element={<MyTransactionsPage />} />
          <Route path="winnings" element={<MyWinningsPage />} />
          <Route path="settings" element={<MySettingsPage />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/"} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
