const Celebration = () => {
  return (
    <svg viewBox="0 0 128 128">
      <path
        fill="#fff0b9"
        d="M115.32129,33.13477a1.50876,1.50876,0,0,0-1.37207-.791l-9.69238.32129-5.75977-7.80176a1.50018,1.50018,0,0,0-2.64844.47461l-2.68945,9.31836L83.958,37.72363a1.49971,1.49971,0,0,0-.36621,2.665l8.03125,5.43653.07422,9.69921a1.50033,1.50033,0,0,0,2.42187,1.17188l7.65137-5.958,9.24707,2.92578a1.49923,1.49923,0,0,0,1.8623-1.94043l-3.30078-9.11914,5.64063-7.88965A1.49924,1.49924,0,0,0,115.32129,33.13477Z"
      />
      <path
        fill="#f7e4a6"
        d="M100.55371,71.0957a1.45866,1.45866,0,0,0-1.37207-.791l-6.95215.23047-4.13086-5.59571a1.50018,1.50018,0,0,0-2.64843.47461l-1.92872,6.68262-6.59863,2.2002a1.4997,1.4997,0,0,0-.36621,2.665l5.75977,3.89942.05273,6.95605A1.50035,1.50035,0,0,0,84.791,88.98926l5.48828-4.27344,6.63086,2.09863A1.4995,1.4995,0,0,0,98.77344,84.874l-2.36719-6.54,4.0459-5.6582A1.49926,1.49926,0,0,0,100.55371,71.0957Z"
      />
      <path
        fill="#efd99c"
        d="M50.00879,22.99609a1.4453,1.4453,0,0,0-1.37207-.791l-11.50879.38183L30.29,13.32422a1.50018,1.50018,0,0,0-2.64844.47461l-3.19336,11.0625-10.92383,3.6416a1.49972,1.49972,0,0,0-.36621,2.665l9.53516,6.45508.08789,11.51465a1.50033,1.50033,0,0,0,2.42187,1.17187l9.085-7.07324,10.97753,3.47461a1.49951,1.49951,0,0,0,1.86329-1.94043L43.209,33.94336l6.69727-9.36719A1.49731,1.49731,0,0,0,50.00879,22.99609Z"
      />
      <path
        fill="#efd99c"
        d="M62.16309,115.78516a2.0001,2.0001,0,0,1-2-2A38.3812,38.3812,0,0,1,76.23633,82.60254a1.99959,1.99959,0,1,1,2.32226,3.25586,34.3773,34.3773,0,0,0-14.3955,27.92676A2.0001,2.0001,0,0,1,62.16309,115.78516Z"
      />
      <path
        fill="#efd99c"
        d="M55.127,110.86816a2.0001,2.0001,0,0,1-2-2A71.98787,71.98787,0,0,1,83.27148,50.38477a1.99959,1.99959,0,1,1,2.32227,3.25585A67.98263,67.98263,0,0,0,57.127,108.86816,2.00009,2.00009,0,0,1,55.127,110.86816Z"
      />
      <path
        fill="#efd99c"
        d="M52.41016,81.53516a2.0001,2.0001,0,0,1-2-2A34.37777,34.37777,0,0,0,36.01465,51.60742a1.99959,1.99959,0,0,1,2.32226-3.25586,38.383,38.383,0,0,1,16.07325,31.1836A2.0001,2.0001,0,0,1,52.41016,81.53516Z"
      />
      <path
        fill="#f7e4a6"
        d="M40.9502,72.85059l-6.59864-2.2002L32.42285,63.9668a1.50018,1.50018,0,0,0-2.64844-.47461l-4.13086,5.5957-6.95214-.23047a1.50015,1.50015,0,0,0-1.27051,2.3711l4.0459,5.6582-2.36719,6.541a1.4995,1.4995,0,0,0,1.86328,1.94043l6.63086-2.09863L33.083,87.542a1.50018,1.50018,0,0,0,2.4209-1.17187l.05273-6.95606,5.75977-3.89844a1.49971,1.49971,0,0,0-.36621-2.665Z"
      />
      <path
        fill="#efd99c"
        d="M50.14648,95.02051a1.99941,1.99941,0,0,1-1.69433-.9336,34.29165,34.29165,0,0,0-9.1377-9.67578,1.9996,1.9996,0,0,1,2.32227-3.25586A38.279,38.279,0,0,1,51.83691,91.9541a1.99984,1.99984,0,0,1-1.69043,3.06641Z"
      />
      <path
        fill="#efd99c"
        d="M73.31836,51.97363l-3.09668-4.20312,1.707-4.9336a1.5006,1.5006,0,0,0-1.89062-1.91406L65.084,42.56934l-4.165-3.14746a1.49954,1.49954,0,0,0-2.4043,1.206l.03515,5.2207-4.28125,2.98731a1.5,1.5,0,0,0,.4043,2.66015l4.97559,1.58008L61.167,58.07031a1.50209,1.50209,0,0,0,1.19141,1.044,1.59273,1.59273,0,0,0,.24414.01953,1.50012,1.50012,0,0,0,1.21875-.626l3.041-4.24316,5.21973.09863a1.5843,1.5843,0,0,0,1.36035-.81055A1.50105,1.50105,0,0,0,73.31836,51.97363Z"
      />
    </svg>
  );
};
export default Celebration;
