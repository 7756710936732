import React, { useState } from "react";
import { Modal, Box, Typography, Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Metamask, Trustwallet, Others } from "../assets";
import { useConnect } from "wagmi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../theme/Theme";

const WalletModal = ({ open, onClose, onWalletSelect }) => {
  const [selectedWallet, setSelectedWallet] = useState(null);

  const { connectors } = useConnect();

  const handleWalletClick = (walletType) => {
    setSelectedWallet(walletType); // Store selected wallet type
  };
  const handleConnectClick = async () => {
    if (selectedWallet) {
      try {
        const { status, address, error } = await onWalletSelect(
          selectedWallet,
          connectors
        );

        if (status === "success") {
          toast.success(`Connected to ${selectedWallet} wallet successfully!`, {
            style: { fontSize: "14px" },
          });
          setTimeout(() => onClose(), 3000); // Delay closing modal
        } else {
          toast.error(`Failed to connect: ${error}`, {
            style: { fontSize: "14px" },
          });
          setTimeout(() => onClose(), 3000); // Delay closing modal
        }
      } catch (error) {
        toast.error(`Failed to connect: ${error.message}`, {
          style: { fontSize: "14px" },
        });
        onClose();
      }
    } else {
      toast.error(`Please select a wallet to connect.`, {
        style: { fontSize: "14px" },
      });
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={styles.modalStyle}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" sx={styles.modalTitle}>
            Select Connection Method
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Box
              onClick={() => handleWalletClick("Metamask")}
              sx={{
                ...styles.walletOption,
                border:
                  selectedWallet === "Metamask"
                    ? "2px solid #000"
                    : "1px solid #ddd",
                boxShadow:
                  selectedWallet === "Metamask"
                    ? "0px 4px 15px rgba(0,0,0,0.3)"
                    : "none",
              }}
            >
              <img src={Metamask} alt="Metamask" width="60" />
              <Typography sx={styles.appTitle}>Metamask</Typography>
            </Box>

            <Box
              onClick={() => handleWalletClick("TrustWallet")}
              sx={{
                ...styles.walletOption,
                border:
                  selectedWallet === "TrustWallet"
                    ? "2px solid #000"
                    : "1px solid #ddd",
                boxShadow:
                  selectedWallet === "TrustWallet"
                    ? "0px 4px 15px rgba(0,0,0,0.3)"
                    : "none",
              }}
            >
              <img src={Trustwallet} alt="TrustWallet" width="60" />
              <Typography sx={styles.appTitle}>TrustWallet</Typography>
            </Box>

            <Box
              onClick={() => handleWalletClick("Other")}
              sx={{
                ...styles.walletOption,
                border:
                  selectedWallet === "Other"
                    ? "2px solid #000"
                    : "1px solid #ddd",
                boxShadow:
                  selectedWallet === "Other"
                    ? "0px 4px 15px rgba(0,0,0,0.3)"
                    : "none",
              }}
            >
              <img src={Others} alt="Other" width="60" />
              <Typography sx={styles.appTitle}>Other Wallet</Typography>
            </Box>
          </Stack>

          <Box sx={styles.buttonWrapper}>
            <Box sx={styles.buttonContainer} onClick={handleConnectClick}>
              <Typography sx={styles.buttonText}>Connect</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default WalletModal;
const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: 700 },
    maxWidth: 800,
    backgroundColor: "background.paper",
    border: "none",
    p: 4,
    borderRadius: "15px",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { xs: "18px", sm: "20px" },
  },
  appTitle: {
    fontSize: "1rem",
    fontWeight: "600",
  },
  walletOption: {
    p: 2,
    width: "150px",
    textAlign: "center",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
    },
  },
  buttonWrapper: {
    mt: 4,
    // width: "25%",
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: "0.15em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    padding: "0.3em 1em",
    borderRadius: "10px",
  },
  buttonText: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
  },
};
