import { Box, Stack, Typography } from "@mui/material";
import TicketIcon from "../../assets/svg/TicketIcon";
import TotalParticipatesIcon from "../../assets/svg/TotalParticipatesIcon";
import TotalWinIcon from "../../assets/svg/TotalWinIcon";
import { PageBackgroundImage } from "../../assets";
import theme from "../../theme/Theme";

const DashboardPage = () => {
  return (
    <Box sx={styles.pageContainer}>
      <Box p={{ xs: 2, md: 6, lg: 6 }}>
        <Box sx={styles.ticketBox}>
          <Box>
            <Typography sx={styles.ticketBoxTitleText}>
              My Active Tickets
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <TicketIcon />
              <Typography sx={styles.ticketValueText}>289+</Typography>
            </Stack>
          </Box>
          <Box>
            <Typography sx={styles.ticketBoxTitleText}>Draws Won</Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <TotalWinIcon />
              <Typography sx={styles.ticketValueText}>100+</Typography>
            </Stack>
          </Box>
          <Box>
            <Typography sx={styles.ticketBoxTitleText}>
              Participated in Draws
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <TotalParticipatesIcon />
              <Typography sx={styles.ticketValueText}>3+</Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DashboardPage;
const styles = {
  pageContainer: {
    background: `url(${PageBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  ticketBox: {
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: { xs: "0.5em 0.5em", lg: "1em 2em" },
    margin: "0 auto",
    width: { xs: "100%", lg: "80%" },
  },
  ticketBoxTitleText: {
    fontSize: { xs: "2.5vw", lg: "1.2vw" },
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
  },
  ticketValueText: {
    fontSize: "3vw",
    fontWeight: 700,
    color: "rgba(191, 21, 34, 1)",
  },
  transactionTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textTransform: "uppercase",
  },
  leaderBoardComponent: {
    marginTop: { xs: "3em", lg: "2em" },
    position: "relative",
  },
  pageTitleContainer: {
    background:
      "linear-gradient(142deg, rgba(254,46,46,1) 0%, rgba(191,21,34,1) 100%)",
    padding: "0.5em 1em",
    width: "max-content",
    borderRadius: "8px",
    marginTop: "-0.75em",
  },
  pageTitleText: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
  },
  recentTransactions: {
    padding: { xs: "0.5em", lg: "2em 3em" },
  },
  lastDaysButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    width: "max-content",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "5px 8px",
    gap: 0.5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  lastDaysText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
  },
  tableHeadCell: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textTransform: "uppercase",
  },
  tableBodyCell: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
  },
  leaderboardTableBodyCell: {
    fontSize: { xs: "3vw", lg: "1.2vw" },
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    padding: { xs: "6px", lg: "1em" },
    flex: 1,
    textAlign: "center",
  },
  tableContainer: {
    marginTop: "1em",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 4, // Rounded corners
    border: `1px solid ${theme.palette.primary.main}`, // Border around the container
    overflow: "auto", // Enable scrolling
    "&::-webkit-scrollbar": { width: "0.4em" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#888" },
    "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  leaderboardPageTitle: {
    margin: "0 auto",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -80%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  },
  leaderBoardContent: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  bottomRightImg: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 0,
  },
  TopLeftImg: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
  winningCard: {
    backgroundImage:
      "repeating-linear-gradient(225deg, transparent, transparent 53px, #cb0a32 53px, #cb0a32 106px)",
    backgroundColor: "#bb092f",
    borderRadius: "1em",
    padding: "1em",
    position: "relative",
  },
  levelBgContainer: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #BF1522",
    borderRadius: "1em",
  },
  levelText: {
    padding: "5px 15px",
    fontSize: "1rem",
    fontWeight: 700,
  },
  gameTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#FFFFFF",
    lineHeight: 1.5,
  },
  gameWinner: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
    lineHeight: 1.5,
    textTransform: "uppercase",
  },
  priceText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  winnerPriceText: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  cardWiningDate: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#FFFFFF",
    marginTop: "5px",
  },
  cardWinnerDateValue: {
    fontSize: "1rem",
    fontWeight: 900,
    color: "#FFFFFF",
    marginTop: "2px",
  },
  medalImage: {
    width: 90,
    position: "absolute",
    top: 0,
    right: "15%",
  },
  leaderboardRowStyle: {
    background:
      "radial-gradient(27% 29% at right, #0000 83%,rgba(255, 0, 0, 0.71) 85% 99%,#0000 101%) calc(13px/2) 13px,radial-gradient(27% 29% at left, #0000 83%,rgba(255, 0, 0, 0.71) 85% 99%,#0000 101%) calc(13px/-2) 13px,radial-gradient(29% 27% at top, #0000 83%,rgba(255, 0, 0, 0.71) 85% 99%,#0000 101%) 0 calc(13px/2),radial-gradient(29% 27% at bottom, #0000 83%,rgba(255, 0, 0, 0.71) 85% 99%,#0000 101%) 0 calc(13px/-2) #f44249",
    backgroundSize: "26px 26px",
    marginBottom: "0.5em",
    borderRadius: "1em",
    width: { xs: "100%", md: "80%", lg: "70%" },
  },
};
