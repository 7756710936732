import {
  Box,
  Divider,
  Grid2,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FooterBg, Logo } from "../assets";
import { Link, useNavigate } from "react-router-dom";
import FacebookIcon from "../assets/svg/FacebookIcon";
import InstagramIcon from "../assets/svg/InstagramIcon";
import YouTubeIcon from "../assets/svg/YoutubeIcon";
import theme from "../theme/Theme";
import { TelegramIcon } from "react-share";

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box sx={{ position: "relative", zIndex: 99 }}>
      <Box sx={styles.footer}>
        <Grid2
          container
          spacing={{ xs: 2, sm: 2, md: 4, lg: 8 }}
          p={{ xs: 2, md: 2 }}
        >
          <Grid2 size={{ xs: 12, md: 3, lg: 3 }}>
            <img src={Logo} alt="logo" style={styles.logo} />
            <Typography style={styles.description}>
              SwssDraw is the world’s first blockchain-based lucky draw
              platform, where transparency, fairness, and rewards are powered by
              smart contracts. Join today and experience the future of online
              competitions!
            </Typography>
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 3 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { sm: "flex-start", md: "center", lg: "center" },
            }}
          >
            <Stack direction={"column"}>
              <Typography sx={styles.title}>Links</Typography>
              <Stack direction={"column"} spacing={1}>
                <Link to="/draw" style={{ textDecoration: "none" }}>
                  <Typography sx={styles.links}>DRAWS</Typography>
                </Link>
                <Link to="/referrals" style={{ textDecoration: "none" }}>
                  <Typography sx={styles.links}>REFERRALS</Typography>
                </Link>
                <Link to="/testimonials" style={{ textDecoration: "none" }}>
                  <Typography sx={styles.links}>TESTIMONIALS</Typography>
                </Link>
                <Link to="/contact-us" style={{ textDecoration: "none" }}>
                  <Typography sx={styles.links}>CONTACT US</Typography>
                </Link>
              </Stack>
            </Stack>
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 3 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { sm: "flex-start", md: "center", lg: "center" },
            }}
          >
            <Stack
              direction={"column"}
              sx={{ marginLeft: { xs: 0, sm: 0, md: -10, lg: -10 } }}
            >
              <Typography sx={styles.title}>Contact Us</Typography>
              <Stack direction={"column"} alignItems={"flex-start"} spacing={1}>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    window.location = "mailto:support@swss.io";
                    e.preventDefault();
                  }}
                >
                  <Typography sx={styles.links}>support@swss.io</Typography>
                </Link>
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Typography sx={styles.title}>Social Links</Typography>
            <Stack direction={"row"} spacing={2}>
              {/* <IconButton>
                <FacebookIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <YouTubeIcon />
              </IconButton> */}
              <IconButton
                onClick={() => window.open("https://t.me/swsssmart", "_blank")}
              >
                <TelegramIcon size={30} />
              </IconButton>
            </Stack>
          </Grid2>
        </Grid2>
        <Divider sx={{ mt: 3, mb: 3 }} />
        {isMobile ? (
          <Box sx={styles.mobileFooter}>
            <Typography
              sx={{
                ...styles.footerLinks,
                textAlign: "center",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              &copy; 2024 Swss Blockchain Technology. All Rights Reserved.
            </Typography>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
              mt={1}
              mb={2}
              mx={2}
            >
              {/* <Typography
                sx={styles.footerLinks}
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </Typography> */}

              {/* <Typography
                sx={styles.footerLinks}
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Typography> */}
            </Stack>
          </Box>
        ) : (
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            mt={6}
            mb={3}
            mx={2}
          >
            {/* <Typography
              sx={styles.footerLinks}
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              Terms & Conditions
            </Typography> */}
            <Typography
              sx={{
                ...styles.footerLinks,
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              &copy; 2024 Swss Blockchain Technology. All Rights Reserved.
            </Typography>
            {/* <Typography
              sx={styles.footerLinks}
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </Typography> */}
          </Stack>
        )}
      </Box>
    </Box>
  );
};
export default Footer;

const styles = {
  footer: {
    zIndex: 999,
    background: `url(${FooterBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: {
      xs: "cover",
      sm: "cover",
      md: "100% 100%",
      lg: "100% 100%",
    },
    backgroundPosition: "center",
    padding: {
      xs: "0.5em",
      sm: "2em 2em 2em 2em",
      md: "5em 1em 1em 1em",
      lg: "6em 1em 1em 1em",
    },
    marginTop: { xs: "0em", sm: "1em", md: "1em", lg: "1em" },
  },
  logo: {
    width: "7em",
  },
  description: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "0.9rem",
    fontWeight: 600,
    marginTop: "1em",
  },
  title: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "1.7rem",
    fontWeight: 700,
    marginBottom: "0.5em",
  },
  links: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": { cursor: "pointer" },
  },
  footerLinks: {
    color: "rgba(82, 82, 82, 1)",
    fontSize: "0.9rem",
    fontWeight: 600,
    "&:hover": { cursor: "pointer" },
  },
  mobileFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};
