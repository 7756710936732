const TransactionStarted = ({
  width = 32,
  height = 32,
  color2 = "#b91321",
  color1 = "#ff8f8f",
  color3 = "#ffffff",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48">
      <path
        fill={color2}
        d="M36,6H12a4.62,4.62,0,0,0-1,.1h0A5,5,0,0,0,7,11a2,2,0,0,0,2,2h6V28.35A5.8,5.8,0,0,1,17,28a6,6,0,1,1-1.2,11.88,3,3,0,0,0,.61.51C17.34,41,17.78,42,19.33,42c2.17,0,2.17-2,4.33-2s2.17,2,4.33,2,2.17-2,4.34-2,2.16,2,4.33,2c1.56,0,2-1,2.93-1.61A3,3,0,0,0,41,37.84V11A5,5,0,0,0,36,6ZM9,11a3,3,0,0,1,2.22-2.9h0l.21,0A3.06,3.06,0,0,1,12,8a3,3,0,0,1,2.12.87,3.1,3.1,0,0,1,.82,1.53A5.8,5.8,0,0,1,15,11ZM39,37.84a1,1,0,0,1-.47.85,7.25,7.25,0,0,0-1.05.84c-.5.45-.54.47-.82.47s-.32,0-.81-.47a4.83,4.83,0,0,0-7,0c-.49.45-.54.47-.81.47s-.31,0-.8-.47A4.91,4.91,0,0,0,23.92,38a8,8,0,0,0,.49-7H36a1,1,0,0,0,0-2H23.46a1.42,1.42,0,0,0-.2,0,8,8,0,0,0-2.4-2H36a1,1,0,0,0,0-2H20a1,1,0,0,0-1,1,.88.88,0,0,0,.06.28A7.82,7.82,0,0,0,17,26V11a7,7,0,0,0-.67-3H36a3,3,0,0,1,3,3Z"
      />
      <path fill={color1} d="M36,12H30a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
      <path fill={color1} d="M36,16H30a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
      <path fill={color1} d="M36,20H30a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
      <path
        fill={color1}
        d="M25,12H21a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V14A2,2,0,0,0,25,12Zm0,8H21V14h4Z"
      />
      <circle fill={color2} cx="17" cy="34" r="8" />
      <path
        fill={color3}
        d="M17,38a1,1,0,0,1-1-1V34a1,1,0,0,1,2,0v3A1,1,0,0,1,17,38Z"
      />
      <path fill={color3} d="M17,30a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" />
    </svg>
  );
};
export default TransactionStarted;
