import dayjs from "dayjs";

export const iSOToDateTime = (isoDate) => {
  try {
    const formattedDate = dayjs(isoDate).format("DD MMM YYYY, h:mm:ss A");
    return formattedDate;
  } catch (error) {
    return "-";
  }
};
