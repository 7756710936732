import { Box, Typography } from "@mui/material";
import { SpinnerInfinity } from "spinners-react";
import theme from "../theme/Theme";

const DashboardLoader = ({ isLoading, title = "Loading..." }) => {
  return (
    <>
      {isLoading && (
        <Box sx={styles.container}>
          <SpinnerInfinity
            color={theme.palette.primary.dark}
            secondaryColor={theme.palette.primary.main}
            size={40}
            enabled={true}
          />
          <Typography sx={styles.titleText}>{title}</Typography>
        </Box>
      )}
    </>
  );
};
export default DashboardLoader;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    p: 8,
    height: "70vh",
  },
  titleText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
};
