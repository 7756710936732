const CrownIcon = ({
  width = 121,
  height = 87,
  color = "rgba(255, 101, 14, 1)",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 101 67" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5 66.5C21.58 49.2912 16.4942 43.3221 0 40C5.57763 36.4391 9.10279 36.3596 15 39.5C17.2781 35.1026 19.3766 33.9599 24.4301 34.8891C22.9026 36.8792 22 39.3384 22 42C22 48.6274 27.5964 54 34.5 54C41.4036 54 47 48.6274 47 42C47 35.3726 41.4036 30 34.5 30C33.2091 30 31.9639 30.1879 30.7925 30.5366C34.8267 24.2481 38.6414 22.5943 44.5 26.5C45.2146 20.4507 46.2985 17.3879 50.5 13C54.0257 17.1037 55.2092 20.1976 56.5 26.5C61.6188 22.633 64.6604 23.0411 70.1512 30.52C68.9962 30.1819 67.7702 30 66.5 30C59.5964 30 54 35.3726 54 42C54 48.6274 59.5964 54 66.5 54C73.4036 54 79 48.6274 79 42C79 39.2905 78.0646 36.7907 76.4868 34.7821C81.3688 33.808 83.4526 35.0916 86 39.5C92.1914 35.6997 95.3053 36.1228 100.5 40C84.7229 43.4137 78.9874 48.576 77 66.5C54.7828 60.7059 43.0682 60.4474 23.5 66.5Z"
        fill={color}
      />
      <circle cx="12.5" cy="26.5" r="5.5" fill={color} />
      <circle cx="50.5" cy="5.5" r="5.5" fill={color} />
      <circle cx="89.5" cy="26.5" r="5.5" fill={color} />
    </svg>
  );
};
export default CrownIcon;
