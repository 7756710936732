export const CarouselData = [
  {
    title1: "World's First",
    title2: "DRAW ON",
    title3: "BLOCKCHAIN",
    description:
      "Opportunities are waiting for you. Do you wan to grab it and be our next lucky winner?",
  },
  {
    title1: "Revolutionizing",
    title2: "The Concept of",
    title3: "Lucky Draws",
    description: `Your chance to win substantial rewards with complete confidence
             
               thanks to blockchain's unmatched security`,
  },
  {
    title1: "Real Rewards",
    title2: "Trust with",
    title3: "Blockchain",
    description: `Smart contracts ensures secure,
    
               tamper-proof draws and guaranteed prize distribution.`,
  },
];
