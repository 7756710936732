import { ethers } from 'ethers';
import contractAbi from '../assets/abi/swssDraw';
import { contractAddress } from '../config';
import { blockchainNetworks } from '../config/blockchain';
import { toBigNumber, weiToEther, getDateTimeFromEpoch, hexToDecimal } from './common';

export const fetchParticipantCount = async (drawId, blockchain) => {
  try {
    const blockchainData = blockchainNetworks[blockchain];
    // Replace with your Infura or Alchemy API URL
    const provider = new ethers.providers.JsonRpcProvider(blockchainData.infuraUrl);

    // Create a contract instance
    const contract = new ethers.Contract(contractAddress, contractAbi, provider);
    // Call the smart contract function
    const count = await contract.participantCount(drawId);
    // console.log(`${drawId} sold tickets: ${count} `);
    // Return the count
    return count.toString();  // Convert it to string to handle large numbers
  } catch (error) {
    console.error("Error fetching participant count:", error);
    return 0;
  }
};

export const getDrawConfigData = async (drawId, blockchain) => {
  try {
    const blockchainData = blockchainNetworks[blockchain];
    // Replace with your Infura or Alchemy API URL
    const provider = new ethers.providers.JsonRpcProvider(blockchainData.infuraUrl);

    // Create a contract instance
    const contract = new ethers.Contract(contractAddress, contractAbi, provider);
    // Call the smart contract function
    const drawConfig = await contract.getDrawConfig(drawId);
    console.log(drawConfig);
    const ticketPrice = weiToEther(drawConfig.ticketPrice);
    const startTime = getDateTimeFromEpoch(drawConfig.startTime);
    const endTime = getDateTimeFromEpoch(drawConfig.endTime);
    const maxParticipants = hexToDecimal(drawConfig[4]).toString();
    const withdrawalFee = (drawConfig[12][1] / 100).toString();
    // console.log('ticketPrice: ' + ticketPrice);
    // console.log('startTicketNumber: ' + startTicketNumber);

    const data = {
      ticketPrice,
      startTime,
      endTime,
      maxParticipants,
      withdrawalFee
    }
    console.log(` id ${drawId} config data`);
    console.log(data);
    // Return the count
    return data;  // Convert it to string to handle large numbers
  } catch (error) {
    console.error("Error fetching participant count:", error);
    return 0;
  }
}