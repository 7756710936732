import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import { WinnerImg } from "../../../assets";
import { FAQData } from "../data/FAQData";
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import theme from "../../../theme/Theme";

const ReferralProgram = ({ navigate }) => {
  const handleReferralClicked = () => {
    navigate("/referrals");
  };
  return (
    <Box
      sx={{
        position: "relative",
        padding: "1em",
        height: { xs: "40em", sm: "60em", md: "50em", lg: "47em" },
      }}
    >
      <Typography
        sx={{ ...styles.titleText, color: "#FFFFFF", textAlign: "left" }}
      >
        REFERRAL <span style={styles.titleHighLight}>PROGRAM</span>
      </Typography>
      <Box sx={styles.referralBg} />
      <Box sx={styles.referralBox}>
        <Box sx={styles.referralBoxInfo}>
          <Typography sx={styles.referralBoxTitle}>
            REFER YOUR <span style={styles.referralBoxTitleBold}>FRIEND</span>
          </Typography>
          <Typography sx={styles.referralBoxSubTitle}>
            AND GET REWARDED INSTANTLY
          </Typography>

          <Stack
            direction={"row"}
            spacing={2}
            mt={{ xs: 2, sm: 5, md: 6, lg: 8 }}
            pl={2}
            mb={{ xs: 5, sm: 5, md: 12, lg: 15 }}
          >
            {/* <Button
              size="medium"
              variant="contained"
              sx={styles.viewMoreButton}
              onClick={() => {
                navigate("/referrals");
              }}
            >
              Refer Friend
            </Button> */}
            <Button
              size="medium"
              variant="contained"
              sx={styles.viewMoreButton}
              onClick={handleReferralClicked}
            >
              Learn More
            </Button>
          </Stack>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={10}
          px={{ xs: 1, sm: 2, md: 10, lg: 15 }}
          sx={{
            marginTop: { xs: "0em", sm: "0em", md: "-10em", lg: "-10em" },
          }}
        >
          <Box sx={{ width: { sm: "100%", md: "50%" } }}>
            <Typography sx={styles.faqTitle}>
              FREQUENTLY ANSWERED QUESTIONS
            </Typography>
            <Typography sx={styles.faqDesc}>
              We're here to assist you every step of the way. If you have any
              questions or need further clarification, feel free to reach out to
              our support team at support@swss.io. Your experience on SwssDraw
              matters to us, and we’re committed to providing the best support
              to ensure your journey is smooth and enjoyable.
            </Typography>
            <Stack
              direction={"row"}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              justifyContent={"center"}
            >
              <img
                src={WinnerImg}
                style={{
                  height: "10em",
                  display: { sm: "none", md: "flex" },
                }}
                alt="faq_image"
              />
            </Stack>
          </Box>
          <Box sx={styles.faqBox}>
            {FAQData.map((item, index) => (
              <Accordion
                key={index}
                sx={{
                  boxShadow: "none", // Remove shadow
                  backgroundColor: index % 2 !== 0 ? "#f3f3f3" : "transparent", // Remove background color
                  "&:before": { display: "none" }, // Remove the default background line on expanded state
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <PlusIcon width={24} color={theme.palette.primary.main} />
                  }
                >
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <QuestionMarkCircleIcon
                      width={24}
                      color={theme.palette.primary.main}
                    />
                    <Typography sx={styles.faqBoxTitle}>
                      {item.title}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={styles.faqBoxDesc}>
                    {item.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
export default ReferralProgram;
