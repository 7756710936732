import { Box, Grid2, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import {
  Crown,
  DrawImg,
  LoginWalletIcon,
  RegisterImage,
  TicketIcon,
  TrophyIcon,
  WinnerImg,
} from "../../../assets";
import TopLeft from "../../../assets/svg/TopLeft";
import BottomRight from "../../../assets/svg/BottomRight";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const HowItWorks = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section style={styles.howItWorks}>
      <Box sx={styles.topDivider} />
      <Box sx={styles.topRightBg}>
        <TopLeft />
      </Box>
      <Box sx={{ position: "relative", zIndex: 2, height: "100vh" }}>
        <Typography sx={styles.titleText}>
          How It <span style={styles.titleHighLight}>Works</span>
        </Typography>
        <Typography sx={styles.subTitleText}>
          Join the world's first blockchain-based lucky draw in just a few
          simple steps. Secure, transparent, and ready for you to win big!
        </Typography>
        <Grid2
          container
          mt={5}
          px={{ xs: 0.4, sm: 1, lg: 20 }}
          rowSpacing={{ xs: 2, sm: 2, md: 6, lg: 2 }}
          columnSpacing={{ sx: 5, sm: 5, lg: 12 }}
        >
          <Grid2
            item={true}
            size={{ xs: 12, md: 6, lg: 6 }}
            sx={{ width: "100%" }}
          >
            <Box data-aos="fade-up-right" sx={styles.cardPalette}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={{ xs: 2, lg: 4 }}
              >
                <img
                  src={LoginWalletIcon}
                  style={styles.cardImage}
                  alt="how it works"
                />
                <Box>
                  <Typography sx={styles.cardTitle}>
                    Login Wallet <span style={styles.cardTitleNo}>01</span>
                  </Typography>
                  <Typography sx={styles.cardDescription}>
                    Easy connect with MetaMask or TronLink etc.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid2>
          <Grid2
            item={true}
            size={{ xs: 12, md: 6 }}
            mt={{ xs: 4, lg: 8 }}
            sx={{ width: "100%" }}
          >
            <Box data-aos="fade-up-left" sx={styles.cardPalette}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={{ xs: 2, lg: 4 }}
              >
                <img
                  src={DrawImg}
                  style={{ ...styles.cardImage, maxWidth: "2.2em" }}
                  alt="how it works"
                />
                <Box>
                  <Typography sx={styles.cardTitle}>
                    SELECT DRAW <span style={styles.cardTitleNo}>02</span>
                  </Typography>
                  <Typography sx={styles.cardDescription}>
                    Select a draw and number of tickets
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid2>

          <Grid2
            item={true}
            size={{ xs: 12, md: 6 }}
            mt={{ xs: 4, lg: 8 }}
            sx={{ width: "100%" }}
          >
            <Box data-aos="fade-up-right" sx={styles.cardPalette}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={{ xs: 2, lg: 4 }}
              >
                <img
                  src={TicketIcon}
                  style={styles.cardImage}
                  alt="how it works"
                />
                <Box>
                  <Typography sx={styles.cardTitle}>
                    GET TICKET <span style={styles.cardTitleNo}>03</span>
                  </Typography>
                  <Typography sx={styles.cardDescription}>
                    Enter by paying, receive an NFT ticket
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid2>

          <Grid2
            item={true}
            size={{ xs: 12, md: 6 }}
            mt={{ xs: 4, lg: 8 }}
            sx={{ width: "100%" }}
          >
            <Box data-aos="fade-up-left" sx={styles.cardPalette}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={{ xs: 2, lg: 4 }}
              >
                <img
                  src={TrophyIcon}
                  style={{ ...styles.cardImage, height: 60 }}
                  alt="how it works"
                />
                <Box>
                  <Typography sx={styles.cardTitle}>
                    Win Rewards <span style={styles.cardTitleNo}>04</span>
                  </Typography>
                  <Typography sx={styles.cardDescription}>
                    Winners get rewards directly in their wallet.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid2>
        </Grid2>
        <img src={WinnerImg} style={styles.winnerBg} alt="winner_image" />
      </Box>
      <Box sx={styles.bottomDivider} />
      <Box sx={styles.bottomRightBg}>
        <BottomRight />
      </Box>
    </section>
  );
};
export default HowItWorks;
