import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./styles";
import { Crown, MaskedLogo, TrophyCup } from "../../../assets";
import NewTabIcon from "../../../assets/svg/NewTabIcon";
import {
  formatNumber,
  etherToWei,
  getFontColorFromBackground,
  convertToISO,
} from "../../../utils/common";
import CrownIcon from "../../../assets/svg/CrownIcon";
import { MinusIcon, PlusIcon, UserIcon } from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";
import {
  purchaseDrawTicket,
  checkWalletLocked,
  checkToken,
  approveToken,
} from "../../../utils/walletUtils";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { fetchParticipantCount, getDrawConfigData } from "../../../utils/drawDetailsUtils";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useMemo, useState } from "react";
import { DrawsList } from "../../../data/DrawsList";
import StepsModel from "./StepsModel";
import { iSOToDateTime } from "../../../utils/dateUtils";
import { blockchainNetworks } from "../../../config/blockchain";
import logError from "../../../logger/logError";
import dayjs from "dayjs";

const DrawDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { id: drawID } = useParams();
  // const [targetDate, setTargetDate] = useState(null);
  // const [startDate, setStartDate] = useState(null);
  const [soldTickets, setSoldTickets] = useState(0);
  const [remainingTickets, setRemainingTickets] = useState(0);
  const [drawDetails, setDrawDetails] = useState(null); // State for the draw details
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [ticketQty, setTicketQty] = useState(1);
  const [isExpired, setIsExpired] = useState(false);
  const [networkData, setNetworkData] = useState(null);
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [isImageDark, setIsImageDark] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [progressData, setProgressData] = useState({
    steps: [],
    currentStep: 0,
    timer: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configData, setConfigData] = useState({
    ticketPrice: 0,
    startTime: '',
    endTime: '',
    maxParticipants: 0,
    withdrawalFee: 0,
  });


  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const startDateTxt = dayjs(configData.startTime);
    let inputDate = startDateTxt.isAfter(now) ? configData.startTime : configData.endTime;

    const distance = new Date(inputDate).getTime() - now;
    // console.log("distance", inputDate, distance);

    if (distance <= 0 && inputDate != null) {
      setIsExpired(true);
    }
    if (distance > 0) {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setIsExpired(false);
      setTimeLeft({ days, hours, minutes, seconds });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const checkIfFutureDate = (date) => {
    const now = dayjs();
    const inputDate = dayjs(date);
    return inputDate.isAfter(now);
  };

  useEffect(() => {
    if (configData.startTime) {
      const isAfterDate = checkIfFutureDate(configData.startTime);
      setIsFutureDate(isAfterDate);
    }
    const timer = setInterval(calculateTimeLeft, 1000);
    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, [configData.startTime]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchDrawData = async () => {
    try {
      const drawData = DrawsList.find((draw) => draw.id === drawID);
      setNetworkData(blockchainNetworks[drawData.blockchain]);

      // if draw id is not found in the list
      if (!drawData) {
        return <div>Draw not found</div>;
      }

      // Dynamically import the draw details based on the drawID
      const drawDataModule = await import(
        `../../../data/draw-details/draw-${drawID}`
      );
      // console.log("Loaded draw data:", drawDataModule);

      // Ensure that the drawDetails object is loaded
      if (drawDataModule.drawDetails) {
        const drawDetails = {
          ...drawData,
          ...drawDataModule.drawDetails,
        };

        setDrawDetails(drawDetails);
        // const endDateTime = convertToISO(
        //   drawDetails.endDate,
        //   drawData.endTime
        // );
        // const startDateTime = convertToISO(
        //   drawDetails.startDate,
        //   drawData.startTime
        // );
        // setTargetDate(endDateTime);
        // setStartDate(startDateTime);

        const isDarkImage = await getFontColorFromBackground(
          drawDetails.bannerImage
        );
        setIsImageDark(isDarkImage);

        // Convert totalTickets to number for calculations
        const totalTickets = configData.maxParticipants ? Number(configData.maxParticipants) : Number(drawDetails.totalTickets);
        const soldTickets = await fetchParticipantCount(
          drawID,
          drawDetails.blockchain
        );

        const configresult = await getDrawConfigData(drawID, drawDetails.blockchain);
        if (configresult) {
          setConfigData(configresult);
        } else {
          console.log("Failed to get draw config data.");
        }

        // Calculate remaining tickets
        const remainingTickets = totalTickets - soldTickets;
        // console.log("Remaining tickets:", remainingTickets);

        setSoldTickets(soldTickets);
        setRemainingTickets(remainingTickets);
        const progressBarWidth = totalTickets
          ? (soldTickets / totalTickets) * 100
          : 0;
        setProgressBarWidth(progressBarWidth);
      } else {
        // console.log("Draw details not found for drawID:", drawID);
      }
    } catch (error) {
      logError(error);
      // console.log("Error loading draw data:", error);
    }
  };

  useEffect(() => {
    if (drawID) {
      fetchDrawData();
    }
  }, [drawID]);

  let timerInterval;
  const handlePurchase = async () => {
    if (Number(ticketQty) > 0 && Number(ticketQty) < 51) {
      if (isExpired) {
        toast.warn("Draw Completed.", {
          style: { fontSize: "14px" },
        });
        return;
      }
      const isWalletUnlocked = await checkWalletLocked();
      if (!isWalletUnlocked) {
        toast.warn("Please unlock your wallet to purchase tickets.", {
          style: { fontSize: "14px" },
        });
        return;
      }
      purchaseTicket();
    } else {
      toast.warn("You can purchase a maximum of 50 tickets per request.", {
        style: { fontSize: "14px" },
      });
      return;
    }
  };

  const purchaseTicket = async () => {
    const steps = [
      "Approval Started",
      "Approval Done",
      "Transaction Started",
      "Minting NFT",
      "Done",
    ];
    const totalTimerDuration = 50; // Total timer duration in seconds

    try {
      const connectedAddress = Cookies.get("connectedAddress");
      if (!connectedAddress) {
        toast.warn("Please connect to a wallet to proceed.", {
          style: { fontSize: "14px" },
        });
        closeModal();
        return;
      }

      const referrer =
        Cookies.get("referrer") || "0x0000000000000000000000000000000000000000";

      const data = {
        drawId: drawID,
        referrer,
        ticketCount: ticketQty,
        // ticketPrice: drawDetails.pricePerTicket,
        ticketPrice: configData?.ticketPrice,
        tokenContractAddress: drawDetails.tokenContractAddress.toString(),
        blockchain: drawDetails.blockchain,
      };
      let result = { error: false };
      // Step 1: Approval
      await executeStep(0, async () => {
        if (
          data.tokenContractAddress !==
          "0x0000000000000000000000000000000000000000"
        ) {
          const { status, message, contractData } = await checkToken(
            data.tokenContractAddress,
            drawDetails.blockchain
          );
          if (status === "success") {
            openModal();
            setProgressData({
              steps,
              currentStep: 0,
              timer: totalTimerDuration,
            });
            startCountdown(totalTimerDuration);
            const { status, message } = await approveToken(
              etherToWei((data.ticketPrice * ticketQty).toString()),
              contractData.connectedAddress,
              contractData.tokenContract,
              data.tokenContractAddress
            );
            if (status !== "success") {
              if (message?.includes("user rejected transaction")) {
                toast.error("User rejected transaction", {
                  style: { fontSize: "14px" },
                });
                closeModal();
                result = { error: true };
              } else {
                throw new Error(message);
              }
            }
          } else {
            console.error("Purchase Ticket Error: ", message);
            logError(message);
            throw new Error(message);
          }
        }
      });

      // Step 2: Simulate network delay for approval completion
      await executeStep(1, () => simulateNetworkDelay(3000));

      // Step 3: Transaction
      await executeStep(2, async () => {
        const { status, message } = await purchaseDrawTicket(
          data,
          connectedAddress
        );
        if (status !== "success") {
          if (message?.includes("user rejected transaction")) {
            toast.error("User rejected transaction", {
              style: { fontSize: "14px" },
            });
            closeModal();
            result = { error: true };
          } else {
            if (message?.includes("cannot estimate gas")) {
              toast.error("Insufficient funds to purchase ticket", {
                style: { fontSize: "14px" },
              });
              closeModal();
              result = { error: true };
            } else {
              throw new Error(message);
            }
          }
        }
      });

      if (result.error === false) {
        // Step 4: Minting NFT
        await executeStep(3, () => simulateNetworkDelay(3000));

        toast.success("Ticket purchased successfully!", {
          style: { fontSize: "14px" },
        });

        // Step 5: Completed Purchase
        await executeStep(4, () => simulateNetworkDelay(3000));
        handleSuccess();
      }
    } catch (error) {
      console.error("Error:", error.message || error);
      logError(error?.message || error);
      toast.error(error.message || "An error occurred. Please try again.", {
        style: { fontSize: "14px" },
      });
    } finally {
      closeModal();
      clearInterval(timerInterval);
    }
  };

  const handleSuccess = () => {
    window.location.href = "/dashboard/tickets";
    setTimeout(() => {
      closeModal();
    }, [3000]);
  };

  const totalTicketPrice = useMemo(() => {
    if (configData?.ticketPrice) {
      return ticketQty * configData?.ticketPrice;
    } else {
      return 0;
    }
  }, [ticketQty, configData.ticketPrice]);

  const startCountdown = (duration) => {
    setProgressData((prev) => ({ ...prev, timer: duration }));
    let remainingTime = duration;

    timerInterval = setInterval(() => {
      remainingTime -= 1;
      setProgressData((prev) => ({ ...prev, timer: remainingTime }));

      if (remainingTime <= 0) {
        clearInterval(timerInterval); // Clear the interval
        setProgressData((prev) => ({ ...prev, timer: 0 })); // Explicitly set timer to 0
      }
    }, 1000);
  };

  // Simulate network delay (for transaction initiation step)
  const simulateNetworkDelay = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  // Execute steps and update progress
  const executeStep = async (stepIndex, stepFunction) => {
    // Update progress to the current step
    setProgressData((prev) => ({ ...prev, currentStep: stepIndex }));

    // Execute the step function (e.g., network requests)
    await stepFunction();
  };

  if (!drawDetails) {
    return <div>Loading...</div>; // Show a loading message or spinner while waiting for drawDetails
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={styles.pageBgImage} />
      <Box sx={styles.bannerImage}>
        <Box
          component={"img"}
          sx={styles.bannerImageView}
          src={drawDetails.bannerImage}
        />
        <Box sx={styles.bannerContent}>
          <Typography
            sx={{
              ...styles.bannerTitle,
              color: isImageDark
                ? "rgba(255, 255, 255, 1)"
                : "rgba(0, 0, 0, 0.9)",
            }}
          >
            Play and Win
          </Typography>
          <Typography
            sx={{
              ...styles.bannerSubTitle,
              color: isImageDark
                ? "rgba(255, 255, 255, 1)"
                : "rgba(0, 0, 0, 0.9)",
            }}
          >
            {drawDetails.title} <br />
            Draw
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.pageContent}>
        <Box sx={styles.drawTitleTicket}>
          <Stack direction={"column"} spacing={1}>
            <Typography sx={styles.letsJoinText}>
              Lets Join the{" "}
              <span style={styles.letsJoinTextHighlight}>Latest</span>
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography sx={styles.letsJoinText}>
                <span style={styles.letsJoinTextHighlight}>Draw</span> and Win
              </Typography>
              <img
                src={Crown}
                alt="crown"
                style={matches ? styles.crownImage : styles.crownSMImage}
              />
            </Stack>
          </Stack>

          <Box sx={styles.drawNumber}>
            <Typography sx={styles.drawNumberText}>
              Draw Code - {drawDetails.drawCode}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.ticketContainer}>
          <Box sx={styles.ticketCard}>
            <Box sx={styles.tickerContent}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={{ xs: 2, md: 4, lg: 5 }}
              >
                <Stack
                  direction={"column"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Typography sx={styles.daysStartEndText}>
                    Competition start
                  </Typography>
                  <Typography sx={styles.daysStartEndTextHighlight}>
                    {/* {iSOToDateTime(configData.startTime)}
                     */}
                    {configData.startTime ? iSOToDateTime(configData.startTime) : "-"}
                  </Typography>
                </Stack>
                <Stack
                  direction={"column"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-start"}
                >
                  <Typography sx={styles.daysStartEndText}>
                    Competition end
                  </Typography>
                  <Typography sx={styles.daysStartEndTextHighlight}>
                    {/* {iSOToDateTime(targetDate)}
                     */}
                    {configData.endTime ? iSOToDateTime(configData.endTime) : "-"}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ my: { xs: 2, sm: 2, lg: 4 } }} />
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems={"center"}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
                spacing={{ xs: 1, md: 2, lg: 2 }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Box
                    sx={{
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography sx={styles.perEntrySmallText}>
                      Per Entry
                    </Typography>
                    <Typography sx={styles.perEntryText}>
                      {/* {drawDetails.currencyTicker} {drawDetails.pricePerTicket} */}
                      {drawDetails.currencyTicker} {formatNumber(configData?.ticketPrice)}
                    </Typography>
                  </Box>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography sx={styles.perEntrySmallText}>
                      Blockchain
                    </Typography>
                    <Typography sx={styles.perEntryText}>
                      {networkData?.networkName}
                    </Typography>
                  </Box>

                  <img
                    src={networkData?.iconUrl}
                    alt="Network Images"
                    style={{ width: 30, height: 30 }}
                  />
                </Stack>
              </Stack>

              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={1}
                  mt={{ xs: 2, lg: 4 }}
                >
                  <Typography sx={styles.ticketValues}>0</Typography>
                  <Typography sx={styles.ticketValues}>
                    Tickets Sold - {soldTickets}
                  </Typography>
                  <Typography sx={styles.ticketValues}>{""}</Typography>
                </Stack>
                <Box sx={styles.progressBarContainer}>
                  <Box
                    sx={{ ...styles.progressBar, width: `${progressBarWidth}` }}
                  ></Box>
                </Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={1}
                >
                  <Typography sx={styles.ticketValues}>
                    {remainingTickets} Tickets Remaining
                  </Typography>

                  <Typography sx={styles.ticketValues}>
                    {configData.maxParticipants || drawDetails.totalTickets || 0}
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    mt: { xs: 5, lg: 12 },
                    mb: { xs: 2, lg: 2 },
                    mx: 1,
                    border: "1px dashed #D9D9D9",
                  }}
                />
                {isExpired || isFutureDate ? (
                  <></>
                ) : (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    justifyContent={"space-between"}
                    sx={{ position: "relative", zIndex: 99 }}
                  >
                    <Typography sx={styles.totalTicketValues}>
                      Total{" "}
                      <span style={styles.totalTicketValueText}>
                        {drawDetails.currencyTicker}{" "}
                        {formatNumber(totalTicketPrice)}
                      </span>
                    </Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      enabled={false}
                      sx={styles.quantityFieldStyle}
                      slotProps={{
                        input: {
                          readOnly: true,
                          startAdornment: (
                            <IconButton
                              sx={styles.quantityFieldMinusIconStyle}
                              onClick={() => {
                                if (Number(ticketQty) > 1) {
                                  setTicketQty((prev) => Number(prev) - 1);
                                }
                              }}
                            >
                              <MinusIcon width={12} height={12} color="#fff" />
                            </IconButton>
                          ),
                          endAdornment: (
                            <IconButton
                              sx={styles.quantityFieldPlusIconStyle}
                              onClick={() => {
                                if (Number(ticketQty) < 50) {
                                  setTicketQty((prev) => Number(prev) + 1);
                                }
                              }}
                            >
                              <PlusIcon width={12} height={12} color="#fff" />
                            </IconButton>
                          ),
                        },
                      }}
                      value={ticketQty}
                      onChange={(e) => setTicketQty(e.target.value)}
                    />
                  </Stack>
                )}

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  justifyContent={"space-between"}
                  mt={{ xs: 2, lg: 1.5 }}
                  sx={{ position: "relative", zIndex: 99 }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={{ xs: 0, lg: 1 }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <a
                      href={
                        networkData?.explorerBaseUrl +
                        "/address/" +
                        drawDetails.tokenContractAddress
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={styles.smartContractText}>
                        Smart Contract Link
                      </Typography>
                      <NewTabIcon />
                    </a>
                  </Stack>
                  {isExpired || isFutureDate ? (
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      {isFutureDate ? "Launching Soon" : "Expired"}
                    </Typography>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        sx={styles.purchaseButton}
                        onClick={handlePurchase}
                      >
                        Purchase Now
                      </Button>
                      <StepsModel
                        drawDetails={drawDetails}
                        steps={progressData.steps}
                        currentStep={progressData.currentStep}
                        timer={progressData.timer}
                        open={isModalOpen}
                        onClose={closeModal}
                      />
                    </>
                  )}
                </Stack>
              </Box>
            </Box>
            <Box component="img" src={MaskedLogo} sx={styles.ticketLogoImage} />
          </Box>
        </Box>
        <Box sx={styles.daysRemaining}>
          <Typography sx={styles.titleText}>
            Days <span style={styles.titleHighLight}>Remaining</span>
          </Typography>
          <Box sx={styles.daysRemainingTimeContainer}>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>
                {timeLeft.days}
              </Typography>
              <Typography sx={styles.daysRemainingTimeIcon}>Days</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>:</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>
                {timeLeft.hours}
              </Typography>
              <Typography sx={styles.daysRemainingTimeIcon}>Hours</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>:</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>
                {timeLeft.minutes}
              </Typography>
              <Typography sx={styles.daysRemainingTimeIcon}>Minutes</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>:</Typography>
            </Box>
            <Box>
              <Typography sx={styles.daysRemainingTimeText}>
                {timeLeft.seconds}
              </Typography>
              <Typography sx={styles.daysRemainingTimeIcon}>Seconds</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.topRewardsContainer}>
        <Box sx={styles.topRewardsBg} />
        <Box sx={styles.topRewards}>
          <Box sx={styles.topRewardsTitle}>
            <Typography sx={styles.topRewardsTitleText}>
              Top <span style={styles.topRewardsTitleHighlight}>3 Rewards</span>
            </Typography>
          </Box>
          {matches ? (
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              justifyContent={"center"}
              spacing={{ xs: "5vw", md: "10vw", lg: "15vw" }}
              sx={{
                margin: "2em auto",
                paddingRight: "15vw",
              }}
            >
              <Box sx={styles.rewardCrownBox} pt={4}>
                <CrownIcon
                  width={91}
                  height={67}
                  color="rgba(192, 192, 192, 1)"
                />

                <Box
                  sx={{
                    ...styles.rewardRankRing,
                    border: "3px solid rgba(192, 192, 192, 1)",
                  }}
                >
                  <Typography sx={styles.rewardRankText}>2</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[1].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.rewardCrownBox}>
                <CrownIcon width={91} height={67} />

                <Box sx={styles.rewardRankRing}>
                  <Typography sx={styles.rewardRankText}>1</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[0].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.rewardCrownBox} pt={4}>
                <CrownIcon
                  width={91}
                  height={67}
                  color="rgba(222, 70, 30, 1)"
                />

                <Box
                  sx={{
                    ...styles.rewardRankRing,
                    border: "3px solid rgba(222, 70, 30, 1)",
                  }}
                >
                  <Typography sx={styles.rewardRankText}>3</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[2].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          ) : (
            <Stack
              direction={{ xs: "column", md: "row", lg: "row" }}
              justifyContent={"center"}
              spacing={{ xs: 5, md: 30, lg: 50 }}
              sx={{
                margin: { xs: "1em auto", md: "2em auto" },
                paddingRight: { xs: "2em", lg: "12em" },
                paddingLeft: { xs: "2em", lg: 0 },
              }}
            >
              <Box sx={styles.rewardCrownBox}>
                <CrownIcon width={101} height={87} />

                <Box sx={styles.rewardRankRing}>
                  <Typography sx={styles.rewardRankText}>1</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[0].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.rewardCrownBox} pt={3}>
                <CrownIcon
                  width={101}
                  height={87}
                  color="rgba(192, 192, 192, 1)"
                />

                <Box
                  sx={{
                    ...styles.rewardRankRing,
                    border: "3px solid rgba(192, 192, 192, 1)",
                  }}
                >
                  <Typography sx={styles.rewardRankText}>2</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[1].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.rewardCrownBox} pt={3}>
                <CrownIcon
                  width={101}
                  height={87}
                  color="rgba(222, 70, 30, 1)"
                />

                <Box
                  sx={{
                    ...styles.rewardRankRing,
                    border: "3px solid rgba(222, 70, 30, 1)",
                  }}
                >
                  <Typography sx={styles.rewardRankText}>3</Typography>
                </Box>
                <Box sx={styles.rewardAmountContainer}>
                  <Typography sx={styles.rewardAmountText}>
                    {`${drawDetails.currencyTicker} ${formatNumber(
                      drawDetails.rewardsTable[2].rewardWinnerPrize
                    )}`}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          )}

          <Divider sx={{ pt: 8, mb: 4, mx: 8 }} />
          <TableContainer sx={{ marginBottom: "1em" }}>
            <Table sx={{ border: "none" }}>
              <TableHead>
                <TableRow sx={{ border: "none" }}>
                  <TableCell align="center" sx={{ border: "none" }}>
                    <Typography sx={styles.tableHeadText}>Level</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ border: "none" }}>
                    <Typography sx={styles.tableHeadText}>
                      Reward Pool Share
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ border: "none" }}>
                    <Typography sx={styles.tableHeadText}>
                      Pool Prize
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ border: "none" }}>
                    <Typography sx={styles.tableHeadText}>
                      Winner Prize
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ border: "none" }}>
                    <Typography sx={styles.tableHeadText}>
                      Total Winners
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drawDetails.rewardsTable.slice(3).map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        index % 2 !== 0
                          ? "rgba(242, 242, 242, 1)"
                          : "rgba(255, 255, 255, 1)",
                    }}
                  >
                    <TableCell align="center" sx={{ border: "none" }}>
                      <Typography sx={styles.tableCellText}>
                        {item.level}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ border: "none" }}>
                      <Typography sx={styles.tableCellText}>
                        {item.rewardPoolPercent}%
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ border: "none" }}>
                      <Typography sx={styles.tableCellText}>
                        {drawDetails.currencyTicker}{" "}
                        {formatNumber(item.rewardPrize)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ border: "none" }}>
                      <Typography sx={styles.tableCellText}>
                        {drawDetails.currencyTicker}{" "}
                        {formatNumber(item.rewardWinnerPrize)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ border: "none" }}>
                      <Typography sx={styles.tableCellText}>
                        {item.totalWinners}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={styles.winProbabilityContainer}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Box
              component="img"
              src={TrophyCup}
              alt="TrophyCup"
              style={matches ? styles.trophyCupImage : styles.trophyCupImageSm}
            />
            <Box>
              <Typography sx={styles.probabilityTitleText}>
                Win Probability
              </Typography>
              <Typography sx={styles.probabilityValueText} mt={1}>
                {drawDetails.winingProbability}
              </Typography>
            </Box>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ color: "rgba(192, 192, 192, 1)" }}
          />
          <Box>
            <Typography sx={styles.probabilityTitleText}>
              Number of Winners
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              mt={1}
              spacing={{ xs: 1, lg: 2 }}
            >
              {matches ? (
                <UserIcon width={50} height={50} />
              ) : (
                <UserIcon width={20} height={20} />
              )}

              <Typography sx={styles.probabilityValueText}>
                {drawDetails.totalWinners}
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Typography sx={styles.declaimerText}>
          Disclaimer: The number of winners and prize amounts may change based on participation levels. Please note that withdrawal fees will apply to this draw.
          For more details, visit our{" "}
          <Link style={styles.disclaimerLink} to="/disclaimer">
            Disclaimer Page
          </Link>
          .
        </Typography>
        <Box sx={styles.referralButtonContainer}>
          <Button
            variant="contained"
            sx={styles.referralButtonStyle}
            onClick={() => {
              navigate("/referrals");
            }}
          >
            REFERRAL LINK
          </Button>
        </Box>
      </Box>
      <Box sx={styles.winProbabilityBox}>
        <Box sx={styles.referralWinnerContainer}>
          <Box sx={styles.referralWinnerBg}>
            <Box sx={styles.referralTextContainer}>
              <Typography sx={styles.referralText}>
                Get A chance to win
              </Typography>
              <Typography sx={styles.referralText}>At the top</Typography>
            </Box>

            <Button
              variant="contained"
              sx={styles.referralButton}
              onClick={handlePurchase}
            >
              Participate Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DrawDetails;
