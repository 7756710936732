import { Box } from "@mui/material";
import HowItWorks from "./components/HowItWorks";
import ActiveCompetitions from "./components/ActiveCompetitions";
import HomeCarousel from "./components/HomeCarousel";
import RecentWinners from "./components/RecentWinners";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import ReferralProgram from "./components/ReferralProgram";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referral_code");

    if (!referralCode) {
      navigate("/", { replace: true });
      return;
    }

    const existingReferrer = Cookies.get("referrer");
    if (existingReferrer) {
      navigate("/", { replace: true });
      return;
    }

    const address = Cookies.get("connectedAddress");

    if (!address || referralCode !== address) {
      Cookies.set("referrer", referralCode, { expires: 30, path: "/" });
    }

    navigate("/", { replace: true });
  }, [location.search, navigate]);
  
  return (
    <Box>
      <HomeCarousel />
      <HowItWorks />
      <ActiveCompetitions />
      <RecentWinners />
      <ReferralProgram navigate={navigate} />
    </Box>
  );
};
export default Home;
