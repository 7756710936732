import {
  Box,
  Typography,
  Stack,
  Divider,
  Collapse,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Grid2,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchUserTickets } from "../../../utils/ticketUtils";
import { Ticket } from "../../../assets";
import TicketIcon from "../../../assets/svg/TicketIcon";
import { SiNintendogamecube } from "react-icons/si";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { formatNumber } from "../../../utils/common";
import { RiNftFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import DashboardLoader from "../../../component/DashboardLoader";
import { iSOToDateTime } from "../../../utils/dateUtils";
import { CiShare1 } from "react-icons/ci";
import { DrawsList } from "../../../data/DrawsList";
import { blockchainNetworks } from "../../../config/blockchain";
import theme from "../../../theme/Theme";
import commonStyle from "../common.style";

const MyTicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openRowId, setOpenRowId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRowClick = (id) => {
    setOpenRowId((prev) => (prev === id ? null : id));
  };

  const loadTickets = async () => {
    setLoading(true);
    const fetchedTickets = await fetchUserTickets();
    setTickets(fetchedTickets);
    setLoading(false);
  };

  const getBlockchainIcon = (drawName) => {
    const list = DrawsList.find((draw) => draw.title === drawName);
    return blockchainNetworks[list.blockchain].iconUrl;
  };

  useEffect(() => {
    setTimeout(() => {
      loadTickets();
    }, 1000);
  }, [location.pathname]);

  const handleNFTClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DashboardPageTitle title={"My Tickets"} />
        {/* <Box sx={styles.settingsStyle}>
          <AdjustmentsVerticalIcon width={20} height={20} />
          <Typography
            sx={{
              fontSize: { xs: "0.7rem", lg: "0.8rem" },
              fontWeight: 600,
              marginLeft: "5px",
            }}
          >
            Active
          </Typography>
        </Box> */}
      </Stack>

      {loading ? (
        <DashboardLoader isLoading={loading} />
      ) : (
        <>
          <TableContainer component={Paper} sx={commonStyle.tableBackground}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.custom.tableHeaderBg }}>
                  <TableCell sx={commonStyle.ticketTitleStyle}></TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Draw Code
                  </TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Draw Name
                  </TableCell>

                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Tickets Purchased
                  </TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Start Date/Time
                  </TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    End Date/Time
                  </TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Pool Prize
                  </TableCell>
                  <TableCell sx={commonStyle.ticketTitleStyle}>
                    Win Probability
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={8}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            ...commonStyle.noTransactions,
                            paddingBottom: 1,
                          }}
                        >
                          No tickets found for this account.
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            mb: 2,
                          }}
                          onClick={() => navigate("/draw")}
                        >
                          Buy Ticket
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  tickets.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        onClick={() => handleRowClick(row.id)}
                        hover
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          <img
                            src={getBlockchainIcon(row.name)}
                            alt=""
                            style={{ width: "20px", height: "20px" }}
                          />
                        </TableCell>
                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          {row.drawId}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...commonStyle.ticketValueTitleStyle,
                            fontWeight: 700,
                            color: theme.palette.primary.main,
                          }}
                        >
                          {row.name}
                        </TableCell>

                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          {row.ticketNumber}
                        </TableCell>
                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          {iSOToDateTime(row.startDate)}
                        </TableCell>
                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          {iSOToDateTime(row.endDate)}
                        </TableCell>
                        <TableCell sx={commonStyle.ticketValueTitleStyle}>
                          {`${row.currencyTicker} ${formatNumber(
                            row.poolPrize
                          )}`}
                        </TableCell>
                        <TableCell>
                          <Typography sx={commonStyle.ticketValueTitleStyle}>
                            {row.winProbability}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {openRowId === row.id ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            margin: 0,
                          }}
                        >
                          <Collapse
                            in={openRowId === row.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid2
                              container
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                width: "100%",
                                borderRadius: "8px",
                              }}
                              spacing={0.5}
                            >
                              {row.ticketData.map((item, index) => (
                                <Grid2
                                  item
                                  size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
                                  sx={styles.ticketBg}
                                  key={index}
                                >
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <Stack
                                      direction={"column"}
                                      alignItems={"center"}
                                      gap={0.5}
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        sx={{
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                        gap={0.3}
                                      >
                                        <SiNintendogamecube
                                          color={theme.palette.primary.main}
                                          width={12}
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: theme.palette.primary.main,
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Transaction Link
                                        </Typography>
                                      </Stack>

                                      {/* <Typography>Explorer</Typography>
                                    <FaCat
                                      color=theme.palette.primary.main
                                      width={12}
                                    /> */}
                                    </Stack>

                                    <Box
                                      sx={styles.ticketDrawId}
                                      onClick={() =>
                                        window.open(
                                          `/draw/${item.drawId}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <Typography
                                        sx={styles.ticketDrawIdText}
                                      >{`DRAW ID : ${item.drawId}`}</Typography>

                                      <CiShare1 size={20} color="#FFFFFF" />
                                    </Box>
                                  </Stack>
                                  <Divider sx={{ my: 2 }} />
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <Box>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                      >
                                        <TicketIcon
                                          color={"#000000"}
                                          width={16}
                                          height={16}
                                        />
                                        <Typography
                                          sx={styles.ticketTitleStyle}
                                          ml={0.3}
                                        >
                                          TICKET NUMBER
                                        </Typography>
                                      </Stack>

                                      <Typography
                                        sx={styles.ticketValueTitleStyle}
                                      >
                                        {item.ticketNumber}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        sx={{
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                        onClick={() => {
                                          handleNFTClick(item.nftExplorerUrl);
                                        }}
                                      >
                                        <RiNftFill
                                          color="rgba(0, 0, 0, 1)"
                                          width={12}
                                        />
                                        <Typography
                                          sx={{
                                            ...styles.ticketTitleStyle,
                                            textAlign: "right",
                                          }}
                                          ml={0.3}
                                        >
                                          NFT Number
                                        </Typography>
                                      </Stack>

                                      <Typography
                                        sx={{
                                          ...styles.ticketValueTitleStyle,
                                          textAlign: "right",
                                          color: theme.palette.primary.main,
                                          textDecoration: "underline",
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                        onClick={() => {
                                          handleNFTClick(item.nftExplorerUrl);
                                        }}
                                      >
                                        {`#${item.NFTNumber}`}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                  <Divider
                                    sx={{ borderStyle: "dashed", mt: 6, mb: 0 }}
                                  />
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%", pt: 2 }}
                                  >
                                    <Box>
                                      <Typography sx={styles.ticketTitleStyle}>
                                        Purchase Date
                                      </Typography>
                                      <Typography
                                        sx={styles.ticketPurchaseDate}
                                      >
                                        {iSOToDateTime(item.purchaseDate)}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography sx={styles.ticketPrizeValue}>
                                        {`${item.currencyTicker} ${item.ticketPrice}`}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Grid2>
                              ))}
                            </Grid2>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default MyTicketsPage;
const styles = {
  settingsStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.3em",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  itemTitle: {
    color: theme.palette.primary.main,
    fontSize: { xs: "3.4vw", lg: "1.6rem" },
    fontWeight: 600,
    textAlign: "center",
  },
  rowItemTitle: {
    color: theme.palette.primary.main,
    fontSize: { xs: "2.8vw", lg: "1rem" },
    fontWeight: 600,
    textAlign: "center",
  },
  rowItemValueTitle: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    textAlign: "center",
  },
  ticketBg: {
    background: `url(${Ticket})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "1em",
  },
  ticketDrawId: {
    backgroundColor: "rgba(0, 0, 0, 1)",
    borderRadius: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 0.5em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  ticketDrawIdText: {
    color: "rgba(255,255, 255,1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "3px 5px",
  },
  ticketTitleStyle: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "0.75rem",
    fontWeight: 600,
    textAlign: "center",
  },
  ticketValueTitleStyle: {
    color: "#525252",
    fontSize: "0.8rem",
    fontWeight: 500,
    textAlign: "center",
    padding: "0.5em 0",
  },
  ticketPurchaseDate: {
    color: theme.palette.primary.main,
    fontSize: { xs: "2.4vw", lg: "0.8rem" },
    fontWeight: 700,
  },
  ticketPrizeValue: {
    color: theme.palette.primary.main,
    fontSize: { xs: "2.8vw", lg: "1rem" },
    fontWeight: 700,
  },
  noTicketsStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "40vh",
  },
};
