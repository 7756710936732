import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import theme from "../../theme/Theme";

const PrivacyPolicyPage = () => {
  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Card sx={styles.cardStyle}>
        <CardHeader title={"Privacy Policy"} />
        <CardContent>
          <Typography sx={styles.dateStyle}>
            Effective Date: 20 September 2024
          </Typography>
          <Typography sx={styles.paraStyles} mt={2}>
            Welcome to{" "}
            <a href="http://swss-draw.web.app/" style={styles.linkStyles}>
              SwssDraw
            </a>
            ! Your privacy is important to us. This Privacy Policy explains how
            we collect, use, and safeguard your information when you participate
            in our lucky draw events or visit our website. By using our
            services, you agree to the terms of this Privacy Policy.
          </Typography>

          <List style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
            <ListItem style={{ ...styles.headingStyle, display: "list-item" }}>
              Information We Collect
              <Typography sx={styles.paraStyles} mt={1}>
                We may collect the following information:
              </Typography>
              <List style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
                <ListItem style={{ display: "list-item" }}>
                  Personal Information
                  <Typography sx={styles.listItemSubText}>
                    &#8226; Email Address
                  </Typography>
                  <Typography sx={styles.listItemSubText}>
                    &#8226; Name
                  </Typography>
                </ListItem>
                <ListItem style={{ display: "list-item" }}>
                  Automatically Collected Information
                  <Typography sx={styles.listItemSubText}>
                    &#8226; Email Address
                  </Typography>
                  <Typography sx={styles.listItemSubText}>
                    &#8226; Name
                  </Typography>
                </ListItem>
                <ListItem style={{ display: "list-item" }}>
                  Third-Party Information
                  <Typography sx={styles.listItemSubText}>
                    &#8226; If you log in or register through third-party
                    services (e.g., Google, Facebook), we may collect the
                    information you authorize these services to share with us.{" "}
                  </Typography>
                </ListItem>
              </List>
            </ListItem>
            <ListItem style={{ ...styles.headingStyle, display: "list-item" }}>
              How We Use Your Information
              <Typography sx={styles.paraStyles}>
                We use your information for the following purposes:
              </Typography>
              <Typography sx={styles.listItemSubText}>
                1. To process your participation in lucky draw events.
              </Typography>
              <Typography sx={styles.listItemSubText}>
                2. To notify you about results, prizes, or updates.
              </Typography>
              <Typography sx={styles.listItemSubText}>
                3. To improve our website and services.
              </Typography>
              <Typography sx={styles.listItemSubText}>
                4. To comply with legal obligations.
              </Typography>
              <Typography sx={styles.listItemSubText}>
                5. To send promotional emails or notifications if you have opted
                in.
              </Typography>
            </ListItem>
            <ListItem style={{ ...styles.headingStyle, display: "list-item" }}>
              How We Protect Your Information
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};
export default PrivacyPolicyPage;
const styles = {
  cardStyle: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
  dateStyle: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 600,
  },
  paraStyles: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 500,
  },
  headingStyle: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 600,
  },
  linkStyles: {
    color: theme.palette.primary.main,
  },
  listItemHeading: {
    display: "list-item",
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 600,
  },
  listItemSubText: {
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    fontWeight: 500,
  },
};
