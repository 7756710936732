import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useState } from "react";
import Header from "./Header";
import { createConfig, http, WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { mainnet, sepolia } from "wagmi/chains";

const DashboardLayout = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const queryClient = new QueryClient();
  const wagmiConfig = createConfig({
    chains: [mainnet, sepolia],
    transports: {
      [mainnet.id]: http(),
      [sepolia.id]: http(),
    },
  });

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  return (
    <Box>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <Header isMobile={isMobile} onDrawerToggle={handleDrawerToggle} />
        </QueryClientProvider>
      </WagmiProvider>

      <Sidebar
        isMobile={isMobile}
        isOpen={isDrawerOpen}
        onClose={handleDrawerToggle}
        onDrawerToggle={(close) => {
          setDrawerOpen(close);
        }}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ml: { sm: `${isMobile ? 0 : 240}px` }, // adjust margin on desktop
          mt: 8,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default DashboardLayout;
