import Web3 from "web3";
import Cookies from "js-cookie";
import swssDrawABI from "../assets/abi/swssDraw";
import { contractAddress } from "../config";
import { DrawsList } from "../data/DrawsList";
import { blockchainNetworks } from "../config/blockchain";

let web3, kittyDrawContract, lastUsedBlockchain;


const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
async function initializeProvider(networkKey, infuraUrl) {
  if (lastUsedBlockchain !== networkKey) {
    web3 = new Web3(new Web3.providers.HttpProvider(infuraUrl));
    kittyDrawContract = new web3.eth.Contract(swssDrawABI, contractAddress);
    lastUsedBlockchain = networkKey;
  }
}

export const fetchUserTickets = async () => {
  try {
    const address = Cookies.get("connectedAddress");
    if (!address) {
      throw new Error("User address not found in cookies.");
    }

    const drawDataList = [];

    for (const drawJsonData of DrawsList) {
      console.log(drawJsonData.blockchain);
      const { blockchain } = drawJsonData;

      if (!blockchain || (blockchain !== "bscMainnet" && blockchain !== "bscTestnet")) {
        console.error(`Blockchain not defined for draw ID: ${drawJsonData.id}`);
        continue;
      }
      const blockchainData = blockchainNetworks[blockchain];
      await initializeProvider(blockchain, blockchainData.infuraUrl);

      const drawId = parseInt(drawJsonData.id, 10);

      const drawConfig = await kittyDrawContract.methods.getDrawConfig(drawId).call();

      const ticketPrice = web3.utils.fromWei(drawConfig.ticketPrice, "ether");
      const startTicketNumber = web3.utils.toBigInt(drawConfig.randomStartNumber);

      const drawData = {
        id: drawId,
        name: drawJsonData?.title ?? "",
        drawId: drawId.toString(),
        ticketNumber: "",
        startDate: drawJsonData?.startDate ?? "",
        endDate: drawJsonData?.endDate ?? "",
        poolPrize: drawJsonData?.totalPrice ?? "0",
        winProbability: drawJsonData?.winingProbability ?? "",
        ticketData: [],
        currencyTicker: drawJsonData?.currencyTicker ?? "",
      };
      await delay(500);
      const events = await kittyDrawContract.getPastEvents("TicketPurchased", {
        filter: { participant: address, drawId: drawId },
        fromBlock: 44775402,
        toBlock: "latest",
      });
      console.log("Printing Events data" + drawId)
      console.log(address)
      console.log(events)
      events.forEach((event) => {
        const {
          ticketNumber, // Single value
          mintedNftTokenId, // Single value
          ticketCount,
          totalAmount,
          timestamp,
        } = event.returnValues;

        const ticketBigNumber = web3.utils.toBigInt(ticketNumber);
        const purchaseDate = new Date(Number(timestamp) * 1000).toISOString();

        if (ticketBigNumber >= startTicketNumber) {
          drawData.ticketData.push({
            drawId: `${drawId}`,
            ticketNumber: `${ticketNumber}`,
            NFTNumber: `${mintedNftTokenId}`,
            tokenURI: `${blockchainData.explorerBaseUrl}/nft/${contractAddress}/${mintedNftTokenId}`,
            purchaseDate,
            ticketPrice: `${ticketPrice}`,
            currencyTicker: drawJsonData?.currencyTicker || "",
            nftExplorerUrl: `${blockchainData.explorerBaseUrl}/nft/${contractAddress}/${mintedNftTokenId}`,
           // totalAmount: web3.utils.fromWei(totalAmount, "ether"), // Total amount in Ether
            //ticketCount: parseInt(ticketCount, 10),
          });
        }
      });

      if (drawData.ticketData.length > 0) {
        drawData.ticketNumber = drawData.ticketData.length;
        drawDataList.push(drawData);
      }
    }
    console.log("Draw Data")
    console.log(drawDataList)
    return drawDataList;
  } catch (error) {
    console.error("Error fetching user tickets:", error);
    return [];
  }
};
