// Header.js
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  Box,
  Divider,
  ListItemIcon,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import {
  Bars3BottomRightIcon,
  FaceSmileIcon,
  TicketIcon,
  HomeIcon,
  ChatBubbleBottomCenterTextIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Logo } from "../assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WalletConnection } from "../service/WalletConnection";
import WalletModal from "../component/WalletModal";
import WalletDisplay from "../component/WalletDisplay";
import theme from "../theme/Theme";

export const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("Home");
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    connectedAddress,
    connectedWalletType,
    isModalOpen,
    handleConnectWalletPress,
    handleDisconnect,
    handleWalletConnect,
    setModalOpen,
  } = WalletConnection();

  const checkDeepLinking = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const wallet = queryParams.get("wallet");
    if (wallet === "Metamask") {
      handleWalletConnect("Metamask", undefined, true);
    } else if (wallet === "TrustWallet") {
      handleWalletConnect("TrustWallet", undefined, true);
    } else if (wallet === "Other") {
      handleWalletConnect("Other", undefined, true);
    }
  };

  useEffect(() => {
    try {
      if (location?.search && location?.search?.includes("wallet=")) {
        checkDeepLinking();

        //handleWalletConnect("Metamask");
      } else {
        if (location.pathname === "/") {
          setActiveNav("Home");
        } else if (location.pathname === "/draws") {
          setActiveNav("Draws");
        } else if (location.pathname === "/referrals") {
          setActiveNav("Referrals");
        } else if (location.pathname === "/testimonials") {
          setActiveNav("Testimonials");
        } else if (location.pathname === "/contact-us") {
          setActiveNav("Contact Us");
        }
      }
    } catch (error) {}
  }, [location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const navIcons = [
    {
      icon: <HomeIcon style={{ width: 24, height: 24 }} />,
      label: "Home",
      path: "/",
    },
    {
      icon: <TicketIcon style={{ width: 24, height: 24 }} />,
      label: "Draws",
      path: "/draw",
    },
    // {
    //   icon: <TrophyIcon style={{ width: 24, height: 24, color: "#ffffff" }} />,
    //   label: "Leaderboard",
    //   path: "/leaderboard",
    // },
    {
      icon: <UserGroupIcon style={{ width: 24, height: 24 }} />,
      label: "Referral",
      path: "/referrals",
    },
    {
      icon: <FaceSmileIcon style={{ width: 24, height: 24 }} />,
      label: "Testimonials",
      path: "/testimonials",
    },
    {
      icon: (
        <ChatBubbleBottomCenterTextIcon style={{ width: 24, height: 24 }} />
      ),
      label: "Contact us",
      path: "/contact-us",
    },
  ];

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveNav("Home");
    } else if (location.pathname === "/draw") {
      setActiveNav("Draws");
    } else if (location.pathname === "/contact-us") {
      setActiveNav("Contact us");
    } else if (location.pathname === "/testimonials") {
      setActiveNav("Testimonials");
    } else if (location.pathname === "/referrals") {
      setActiveNav("Referral");
    } else if (location.pathname === "/leaderboard") {
      setActiveNav("Leaderboard");
    }
  }, [location.pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {isMobile && (
          <IconButton color="inherit" edge="start" onClick={toggleDrawer(true)}>
            <Bars3BottomRightIcon color="#000000" width={24} />
          </IconButton>
        )}

        <Box
          component={"img"}
          src={Logo}
          sx={styles.logoStyle}
          alt="SWSS Wallets"
        />

        {!isMobile && (
          <Box sx={styles.nav}>
            {navIcons.map((navItem) => (
              <Link to={navItem.path} style={styles.navLinkStyle}>
                <Typography
                  sx={{
                    ...styles.navItem,
                    color:
                      activeNav === navItem.label
                        ? "#000000"
                        : theme.palette.primary.main,
                  }}
                >
                  {navItem.label}
                </Typography>
              </Link>
            ))}
          </Box>
        )}
        <WalletDisplay
          connectedAddress={connectedAddress}
          connectedWalletType={connectedWalletType}
          onConnect={handleConnectWalletPress}
          onDisconnect={handleDisconnect}
        />
        <WalletModal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          onWalletSelect={handleWalletConnect}
        />

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box sx={styles.drawerSize} role="presentation">
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ p: 0.5, color: "#FFFFFF" }}
            >
              <Box sx={styles.drawerHeader} onClick={toggleDrawer(false)}>
                <ArrowLeftIcon style={{ height: 22, width: 22 }} />
              </Box>
              <Typography sx={styles.drawerTitleText}>
                SwssDraw | Blockchain based draw system
              </Typography>
            </Stack>
            <Divider />

            <List>
              {navIcons.map((navItem) => (
                <ListItem
                  button
                  key={navItem.label}
                  sx={{
                    fontSize: 16,
                    color: "#000000",
                    backgroundColor:
                      activeNav === navItem.label
                        ? theme.palette.primary.main
                        : theme.custom.white,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "rgba(191, 21, 34, 0.8)",
                      color: theme.custom.white,
                    },
                  }}
                  onClick={() => {
                    setDrawerOpen(false);
                    navigate(navItem.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        activeNav === navItem.label
                          ? "rgba(255,255,255,1)"
                          : "rgba(46, 46, 48, 1)",
                    }}
                  >
                    {navItem.icon}
                  </ListItemIcon>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      color:
                        activeNav === navItem.label
                          ? "rgba(255,255,255,1)"
                          : "rgba(46, 46, 48, 1)",
                    }}
                  >
                    {navItem.label}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: {
      xs: "0.5em 1em",
      sm: "0.5em 1em",
      md: "0.5em 3em",
      lg: "0em 3em",
      xl: "0em 3em",
    },
    gap: 2,
    width: "100%",
  },
  logoStyle: {
    height: {
      xs: "1.6em",
      md: "2em",
    },
  },
  buttonWrapper: {
    border: "1px solid rgba(250, 33, 90, 1)",
    borderRadius: "12px",
    padding: "0.15em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    background:
      "linear-gradient(to right, rgba(250, 33, 90, 1), rgba(158, 3, 44, 1))",
    padding: "0.3em 1em",
    borderRadius: "10px",
  },
  buttonText: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  navItem: {
    fontSize: 16,
    color: "rgba(210, 20, 71, 1)",
    fontWeight: 700,
    transition: "all 0.3s ease-in-out",
    textTransform: "uppercase",
    "&:hover": {
      color: "rgba(0, 0, 0, 1)",
      cursor: "pointer",
    },
  },
  navLinkStyle: {
    textDecoration: "none",
  },
  smallText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "#000",
    marginBottom: "2em",
  },
  drawerSize: {
    width: window.innerWidth * 0.5,
    height: "100vh",
    backgroundColor: "rgba(255,255,255,1)",
  },
  drawerHeader: {
    color: "#FFFFFF",
    mr: 1,
    "&:hover": { cursor: "pointer" },
  },
  drawerTitleText: { fontSize: 16, fontWeight: 600, color: "#FFFFFF" },
};
