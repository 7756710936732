import { Box } from "@mui/material";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { WagmiProvider, createConfig } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const wagmiConfig = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
});

const MainLayout = () => {
  return (
    <Box>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <Header />
        </QueryClientProvider>
      </WagmiProvider>
      <Box component={"main"} mt={8}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
export default MainLayout;
