import Web3 from "web3";
import Cookies from "js-cookie";
import drawHelperABI from "../assets/abi/drawHelper";
import { draHelperContractAddress } from "../config";
import { DrawsList } from "../data/DrawsList";
import { blockchainNetworks } from "../config/blockchain";

let web3, drawHelperContract;

async function initializeProvider(infuraUrl) {
  if (!web3) {
    web3 = new Web3(new Web3.providers.HttpProvider(infuraUrl));
    drawHelperContract = new web3.eth.Contract(
      drawHelperABI,
      draHelperContractAddress
    );
  }
}

export const fetchWinners = async () => {
  try {
    // Fetch the connected user address from cookies
    const address = Cookies.get("connectedAddress");
    if (!address) {
      throw new Error("User address not found in cookies.");
    }

    const drawDataList = [];

    for (const drawJsonData of DrawsList) {
      const drawId = parseInt(drawJsonData.id, 10);
      const blockchainData = blockchainNetworks[drawJsonData.blockchain];
      await initializeProvider(blockchainData.infuraUrl);

      const drawData = {
        id: drawId,
        name: drawJsonData?.title ?? "",
        drawId: drawId.toString(),
        startDate: drawJsonData?.startDate ?? "",
        endDate: drawJsonData?.endDate ?? "",
        poolPrize: drawJsonData?.totalPrice ?? "0",
        winProbability: drawJsonData?.winingProbability ?? "",
        winnerData: [],
        currencyTicker: drawJsonData?.currencyTicker ?? "",
      };

      // Fetch past WinnerSelected events filtered by the user's address
      const events = await drawHelperContract.getPastEvents("WinnerSelected", {
        filter: {
          winnerAddress: address,
          drawId: drawId.toString(),
        }, // Add drawId filter
        fromBlock: 0,
        toBlock: "latest",
      });

      // console.log(
      //   `Events for Draw ID ${drawId} and Address ${address}:`,
      //   events
      // );

      // Map over events to extract winner data
      const winners = events.map((event) => {
        const { level, winnerAddress, ticketNumber, prizeAmount } =
          event.returnValues;
        const prizeInEther = web3.utils.fromWei(prizeAmount, "ether");

        return {
          drawId: `${drawId}`,
          level,
          winnerAddress,
          ticketNumber,
          prizeAmount: prizeInEther,
          explorerUrl: `${blockchainData.explorerBaseUrl}/address/${winnerAddress}`,
          ticketExplorerUrl: `${blockchainData.explorerBaseUrl}/nft/${draHelperContractAddress}/${ticketNumber}`, // Link to ticket
        };
      });

      // If winners exist for this draw, add to drawDataList
      if (winners.length > 0) {
        drawData.winnerData = winners;
        drawDataList.push(drawData);
      }
    }

    return drawDataList;
  } catch (error) {
    console.error("Error fetching user winners:", error);
    return [];
  }
};
