import React, { useState } from "react";
import Cookies from "js-cookie";
import { Box, Typography } from "@mui/material";

const CookieConsentBanner = () => {
  const [isVisible, setIsVisible] = useState(!Cookies.get("cookieConsent"));

  const handleAccept = () => {
    // Set a cookie to store consent
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsVisible(false);
  };

  return (
    isVisible && (
      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <Typography sx={styles.descriptionText}>
            We use cookies to improve your experience. By using our site, you
            accept our use of cookies.{" "}
            <a
              href="/privacy-policy"
              style={{ color: "#4caf50", fontWeight: 500 }}
            >
              Learn more
            </a>
          </Typography>
          <button onClick={handleAccept} style={styles.buttonStyle}>
            Accept
          </button>
        </Box>
      </Box>
    )
  );
};

export default CookieConsentBanner;
const styles = {
  wrapper: { display: "flex", justifyContent: "center", alignItems: "center" },
  container: {
    position: "fixed",
    bottom: 10,
    background: "rgba(0, 0, 0, 0.6)",
    border: "1px solid #4caf50",
    color: "#fff",
    width: { xs: "100%", md: "max-content" },
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1em",
    px: 2,
    py: 1,
  },
  descriptionText: {
    fontSize: "0.9rem",
    fontWeight: 500,
  },
  buttonStyle: {
    marginLeft: "1rem",
    background: "#4caf50",
    color: "#fff",
    border: "none",
    padding: "0.2rem 1rem",
    borderRadius: "1em",
    fontSize: "0.8rem",
    fontWeight: 600,
  },
};
