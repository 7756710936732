import { useState, useEffect } from "react";
import {
  ConnectMetaMask,
  ConnectTrustWallet,
  ConnectWithOther,
} from "../utils/walletUtils";
import { Metamask, Trustwallet, Others } from "../assets";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { websiteURL } from "../config";

export const WalletConnection = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  const [connectedAddress, setConnectedAddress] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [connectedWalletType, setConnectedWalletType] = useState(null);

  const navigate = useNavigate();
  const handleConnectWalletPress = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const storedAddress = Cookies.get("connectedAddress");
    const storedWalletType = Cookies.get("connectedWalletType");

    if (storedAddress && storedWalletType) {
      setConnectedAddress(storedAddress);
      setConnectedWalletType(storedWalletType);
    }
  }, []);

  const handleWalletConnect = async (walletType, connectors, isTriggered) => {
    try {
      let result = null;
      if (walletType === "Metamask") {
        if (!isTriggered && isMobileDevice) {
          window.location.replace(
            `https://metamask.app.link/dapp/${websiteURL}?wallet=Metamask`
          );
        } else {
          result = await ConnectMetaMask();
        }
      } else if (walletType === "TrustWallet") {
        if (!isTriggered && isMobileDevice) {
          window.location.replace(
            `https://link.trustwallet.com/open_url?coin_id=60&url=${websiteURL}?wallet=TrustWallet`
          );
        } else {
          result = await ConnectTrustWallet();
        }
      } else if (walletType === "Other") {
        if (!isTriggered && isMobileDevice) {
          window.location.replace(
            `https://metamask.app.link/dapp/${websiteURL}?wallet=Other`
          );
        } else {
          result = await ConnectWithOther(connectors);
        }
      }

      if (result.status === "success") {
        setConnectedAddress(result.address);
        setConnectedWalletType(walletType);
        setModalOpen(false);
        Cookies.set("connectedAddress", result.address, { expires: 30 });
        Cookies.set("connectedWalletType", walletType, { expires: 30 });

        // localStorage.setItem("connectedAddress", result.address);
        // localStorage.setItem("connectedWalletType", walletType);
        return { status: "success", address: result.address };
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error(error.message);
      setConnectedAddress(null);
      return { status: "error", error: error.message };
    }
  };

  const handleDisconnect = async () => {
    try {
      const walletType = Cookies.get("connectedWalletType");
      if (walletType.trim().toLowerCase() === "metamask") {
        // Request MetaMask to revoke permissions
        await window.ethereum.request({
          method: "wallet_revokePermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        // console.log("MetaMask permissions successfully revoked.");
      }
    } catch (error) {
      console.error("Error revoking MetaMask permissions:", error);
    }
    setConnectedAddress(null);
    setConnectedWalletType(null);
    Cookies.remove("connectedAddress");
    Cookies.remove("connectedWalletType");
    navigate("/");
  };

  return {
    connectedAddress,
    isModalOpen,
    handleConnectWalletPress,
    handleWalletConnect,
    handleDisconnect,
    connectedWalletType,
    setModalOpen,
  };
};

export const getWalletImage = (walletType) => {
  switch (walletType) {
    case "Metamask":
      return Metamask;
    case "TrustWallet":
      return Trustwallet;
    default:
      return Others; // Fallback image for unsupported wallets
  }
};
