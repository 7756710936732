import { getAnalytics } from "@firebase/analytics";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyAdwDvI_hDhzbLR-ZnHFSTstHjtzMt8xro",
  authDomain: "swss-draw.firebaseapp.com",
  projectId: "swss-draw",
  storageBucket: "swss-draw.firebasestorage.app",
  messagingSenderId: "198987080848",
  appId: "1:198987080848:web:e8053c2c611374cdf46415",
  measurementId: "G-NNVZ2NC100",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

export { app, analytics };
