import { ethers } from "ethers";
import { contractAddress } from "../config"; // Import config details
import swssDrawABI from "../assets/abi/swssDraw"; // Import ABI
import { blockchainNetworks } from "../config/blockchain";

/**
 * Fetch all tickets purchased by a specific user.
 * @param {string} userAddress - The user's wallet address.
 * @returns {Promise<Array>} List of tickets purchased by the user.
 */
export async function fetchUserTickets(userAddress) {
  if (!ethers.utils.isAddress(userAddress)) {
    throw new Error("Invalid Ethereum address");
  }

  let provider;
  let previousBlockchain = null; // Track previously used blockchain
  const processedTransactionHashes = new Set(); // Track processed transaction hashes

  try {
    const ticketDataList = [];

    // Iterate through blockchainNetworks object
    for (const blockchainKey in blockchainNetworks) {
      const blockchainData = blockchainNetworks[blockchainKey]; // Get current blockchain data

      // Initialize the provider only if the blockchain changes
      if (previousBlockchain !== blockchainKey) {
        provider = new ethers.providers.JsonRpcProvider(blockchainData.infuraUrl);
        previousBlockchain = blockchainKey;
      }

      // Initialize the contract
      const contract = new ethers.Contract(contractAddress, swssDrawABI, provider);

      // Create a filter for TicketPurchased events with the participant address
      const filter = contract.filters.TicketPurchased(null, userAddress);

      // Fetch events
      const events = await contract.queryFilter(filter);

      // Parse and store ticket data
      events.forEach((event) => {
        const transactionHash = event.transactionHash;

        // Ensure this transactionHash hasn't been processed already
        if (!processedTransactionHashes.has(transactionHash)) {
          processedTransactionHashes.add(transactionHash); // Mark as processed

          ticketDataList.push({
            drawId: event.args.drawId.toString(),
            participant: event.args.participant,
            ticketCount: event.args.ticketCount.toString(),
            totalAmount: ethers.utils.formatEther(event.args.totalAmount),
            transactionHash: transactionHash,
            transactionDate: new Date(event.args.timestamp * 1000).toISOString(), // Convert UNIX timestamp to ISO format
          });
        }
      });
    }

    return ticketDataList;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    throw error;
  }
}

