import {
  CarouselBg,
  HappyCustomerBg,
  HeroBg,
  ReferralBg,
  ReferralInfo,
  TableBg,
} from "../../assets";
import theme from "../../theme/Theme";

export const styles = {
  carouselStyle: {
    background: `url(${HeroBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselBg: {
    background: `url(${CarouselBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: {
      xs: "12em",
      sm: "12em",
      md: "calc(100vh - 56px)",
      lg: "calc(100vh - 56px)",
      xl: "calc(100vh - 56px)",
    },
    padding: { xs: 5, sm: 5, md: 10, lg: 10 },
  },
  carouselDescription: {
    marginLeft: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "2.5em",
      xl: "2.5em",
    },
  },
  heading1: {
    fontSize: { xs: "1rem", sm: "1rem", md: "2.5rem", lg: "2rem" },
    fontWeight: "600",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    padding: "0",
    marginTop: {
      xs: "0em",
      sm: "0em",
      md: "2em",
      lg: "2em",
      xl: "2.5em",
    },
  },
  heading2: {
    fontSize: { sm: "2rem", md: "4.5rem", lg: "3.8rem" },
    fontWeight: "600",
    color: theme.custom.black,
    textTransform: "uppercase",
    lineHeight: 1,
    marginTop: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "0.2em",
      xl: "0.2em",
    },
  },
  heading3: {
    fontSize: { sm: "2rem", md: "4.5rem", lg: "3.8rem" },
    fontWeight: "800",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    lineHeight: 1,
  },
  description: {
    color: theme.custom.emperor,
    fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem", lg: "1.2rem" },
    fontWeight: "500",
    marginTop: "1em",
    textWrap: "wrap",
    maxWidth: "55%",
  },
  buttonWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: { xs: "0.15em", sm: "0.15em", md: "0.15em", lg: "0.15em" },
    width: "8em",
    marginTop: {
      xs: "0.4em",
      sm: "1em",
      md: "1.5em",
      lg: "1.5em",
      xl: "1.5em",
    },
    marginLeft: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "2.5em",
      xl: "2.5em",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    padding: { xs: "0.3em 0.7em", lg: "0.4em 0.8em" },
    borderRadius: "10px",
  },
  buttonText: {
    color: theme.custom.white,
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
  },
  howItWorks: {
    position: "relative",
    minHeight: "100vh",
  },
  topDivider: {
    position: "absolute",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.3) 31%, rgba(255,255,255,0) 100%)",
    left: 0,
    right: 0,
    top: 0,
    height: 100,
    zIndex: 1,
  },
  bottomDivider: {
    position: "absolute",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 31%, rgba(255,255,255,0) 100%)",
    left: 0,
    right: 0,
    bottom: 0,
    height: 100,
    zIndex: 1,
  },
  titleText: {
    position: "relative",
    fontWeight: "800",
    margin: "1em 2em 0.3em 2em",
    paddingTop: { xs: "1em", lg: "0.3em" },
    fontSize: { xs: "5.5vw", lg: "3.2vw" },
    textAlign: "center",
    color: theme.custom.black,
    textTransform: "uppercase",
    zIndex: 99,
  },
  titleHighLight: {
    color: theme.palette.primary.main,
    borderBottom: "1.5px solid rgba(0, 0, 0, 1)",
  },
  subTitleText: {
    fontSize: { xs: "3.5vw", lg: "1vw" },
    color: "rgba(0, 0, 0, 0.8)",
    fontWeight: "600",
    textAlign: "center",
    width: { xs: "90%", md: "70%", lg: "60%" },
    margin: "0 auto",
  },
  happyCustomerSubTitle: {
    fontSize: "1.2rem",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "600",
  },
  cardPalette: {
    position: "relative",
    zIndex: 99,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#FFFFFF",
    padding: {
      xs: "0.6em 0.8em",
      sm: "1em 2em",
      md: "0.8em 1em",
      lg: "0.8em 1em",
    },
    borderRadius: "1em",
    maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "90%" },
  },
  cardImage: {
    maxWidth: "2.6em",
    height: "auto",
  },
  cardTitle: {
    fontSize: { xs: "4vw", lg: "1.5vw" },
    fontWeight: "700",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  cardTitleNo: {
    color: "rgba(213, 213, 213, 1)",
  },
  cardDescription: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: "600",
    color: "rgba(82, 82, 82, 1)",
    lineHeight: 1.2,
    marginTop: "5px",
  },
  winnerBg: {
    position: "absolute",
    width: "300px",
    bottom: "20%",
    left: 0,
    right: 0,
    margin: "0 auto",
    zIndex: -1,
  },
  activeCompetitions: {
    position: "relative",
    background: `linear-gradient(325deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 87%, ${theme.custom.primaryDarker} 100%)`,
    width: "100vw",
    top: 0,
    zIndex: 2,
  },
  activeCompetitionBg: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    width: "calc(100% - 10px)",
    margin: "0 auto",
    zIndex: -1,
  },
  competitionCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
    margin: { sx: 1, md: 2, lg: 3 },
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 240,
  },
  titleContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "0.5em 1em",
    zIndex: 3,
  },
  competitionCardImage: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: 240,
    width: "100%",
    objectFit: "fill",
    borderRadius: "1em 1em 0 0",
    zIndex: 2,
  },
  competitionCardTitle: {
    fontSize: { xs: "4.2vw", lg: "1.8vw" },
    fontWeight: "800",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    textAlign: "center",
  },
  totalPoolPriceText: {
    fontSize: { xs: "3.2vw", lg: "1.25vw" },
    fontWeight: 500,
    color: "rgba(51, 51, 51, 1)",
    display: "block",
    marginLeft: 2,
  },
  totalPoolPriceTicker: {
    fontWeight: 600,
    fontSize: { xs: "2.5vw", lg: "1vw" },
    color: theme.palette.primary.main,
    marginLeft: 2,
  },

  totalPoolPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.3vw", lg: "1.28vw" },
    color: theme.palette.primary.main,
  },
  otherTitleText: {
    fontSize: { xs: "3.1vw", lg: "1vw" },
    fontWeight: 600,
    color: "#706F6F",
    lineHeight: 1.2,
  },
  blockchainIcon: {
    margin: "0 0.5em",
  },
  otherPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.2vw", lg: "1.2vw" },
    color: "#3F3F42",
    lineHeight: 1.2,
  },
  progressBarContainer: {
    position: "relative",
    height: "10px",
    width: "100%",
    borderRadius: "10em",
    overflow: "hidden",
    backgroundColor: "#DADADA",
  },
  soldTickers: {
    backgroundColor: "#0093E9",
    backgroundImage:
      "linear-gradient(90deg, #2AF598 0%, #eae608 31%, #fb9b03 65%, #ff0004 100%)",
    height: "10px",
    borderRadius: "10em",
  },
  progressText: {
    fontSize: { xs: "2vw", lg: "0.9vw" },
    fontWeight: 600,
    color: "#3E3E3E",
    lineHeight: 1.2,
  },
  competitionCardDescription: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "rgba(82, 82, 82, 1)",
  },
  competitionCardButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  competitionCardContent: {
    paddingTop: "0.5em",
  },
  bookmarkIcon: {
    border: "1.4px solid rgba(191, 21, 34, 1)",
    borderRadius: "50%",
    padding: "0.2em",
    width: "1.8em",
    height: "1.8em",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  daysText: {
    color: "#3F3F42",
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 600,
  },
  pricePerTicket: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "1em",
  },
  joinButtonText: {
    backgroundColor: theme.palette.primary.main,
    padding: "0.5em 1.4em",
    borderBottomRightRadius: "1em",
    borderTopLeftRadius: "1em",
    color: "white",
    fontSize: { xs: "3.1vw", lg: "1.1vw" },
    fontWeight: "500",
  },
  viewMoreButton: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: { xs: "3vw", lg: "1.2vw" },
    fontWeight: 600,
    textTransform: "uppercase",
    marginTop: "2em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  happyCustomerTitle: {
    fontWeight: "800",
    fontSize: { xs: "5.5vw", lg: "3.2vw" },
    color: "rgba(0, 0, 0, 1)",
    textTransform: "uppercase",
    textAlign: "left",
  },
  happyCustomerHighLight: {
    color: "rgba(255, 255, 255, 1)",
    borderBottom: "1.5px solid rgba(0, 0, 0, 1)",
  },
  happyCustomerSection: {
    background: `url(${HappyCustomerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 50%",
    backgroundPosition: "center",
    position: "absolute",
    width: "100vw",
    padding: "4em",
    left: 0,
    right: 0,
    zIndex: -1,
  },
  customerCardHolder: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1,
    margin: { lg: "0 3em" },
  },
  happyCustomerCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
    margin: { xs: "1em", sm: "1em", md: "2em", lg: "2em" },
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s ease",
  },
  customerName: {
    fontSize: { xs: "3.8vw", lg: "1.6vw" },
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 1)",
  },
  customerDesignation: {
    fontSize: { xs: "3.2vw", lg: "1.2vw" },
    fontWeight: 700,
    textAlign: "center",
    marginTop: "0.5em",
    textTransform: "uppercase",
    color: "rgba(82, 82, 82, 1)",
  },
  customerDescription: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 500,
    textAlign: "center",
    color: "rgba(137, 137, 137, 1)",
    marginTop: "1em",
  },
  viewMoreRedButton: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
    marginTop: "2em",
    marginBottom: "1em",
    marginRight: "2em",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  celebrationBg: {
    width: "100vw",
  },
  celebrationImage1: {
    position: "absolute",
    right: 20,
    top: 0,
    width: "4em",
    zIndex: -1,
  },
  celebrationImage2: {
    position: "absolute",
    left: 30,
    top: 5,
    width: "2em",
    zIndex: -1,
  },
  celebrationImage3: {
    position: "absolute",
    left: 90,
    top: 80,
    width: "4em",
    zIndex: -1,
  },
  celebrationImage4: {
    position: "absolute",
    left: 15,
    bottom: 10,
    transform: "rotate(-40deg)",
    width: "4em",
    zIndex: -1,
  },
  celebrationImage5: {
    position: "absolute",
    left: "30%",
    top: 50,
    transform: "rotate(30deg)",
    width: "4em",
    zIndex: -1,
  },
  winnerTickerNo: {
    backgroundColor: "#ffffff",
    border: "1px solid rgba(255, 143, 152, 1)",
    height: "1.6em",
    width: "1.6em",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(82, 82, 82, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0.2em",
  },
  tableHeadCell: {
    fontSize: { xs: "0.8rem", lg: "1rem" },
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
  },
  referralBox: {
    position: "relative",
    zIndex: 1,
    paddingBottom: { xs: "1em", md: "6em", lg: "6em" },
    paddingTop: "1em",
    overflow: "hidden",
  },
  referralBg: {
    position: "absolute",
    clipPath:
      "polygon(100% 100%, 0% 100% , 0.00% 1.33%, 2.00% 1.36%, 4.00% 1.45%, 6.00% 1.60%, 8.00% 1.80%, 10.00% 2.06%, 12.00% 2.38%, 14.00% 2.75%, 16.00% 3.16%, 18.00% 3.63%, 20.00% 4.14%, 22.00% 4.69%, 24.00% 5.27%, 26.00% 5.89%, 28.00% 6.54%, 30.00% 7.21%, 32.00% 7.90%, 34.00% 8.61%, 36.00% 9.33%, 38.00% 10.05%, 40.00% 10.78%, 42.00% 11.50%, 44.00% 12.22%, 46.00% 12.92%, 48.00% 13.60%, 50.00% 14.26%, 52.00% 14.89%, 54.00% 15.49%, 56.00% 16.06%, 58.00% 16.59%, 60.00% 17.07%, 62.00% 17.51%, 64.00% 17.90%, 66.00% 18.24%, 68.00% 18.53%, 70.00% 18.76%, 72.00% 18.93%, 74.00% 19.05%, 76.00% 19.10%, 78.00% 19.10%, 80.00% 19.04%, 82.00% 18.92%, 84.00% 18.74%, 86.00% 18.51%, 88.00% 18.22%, 90.00% 17.87%, 92.00% 17.48%, 94.00% 17.04%, 96.00% 16.55%, 98.00% 16.02%, 100.00% 15.45%)",
    background: `linear-gradient(325deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 87%, ${theme.custom.primaryDarker} 100%)`,
    top: 0,
    left: 0,
    right: 0,
    bottom: "-10em",
    zIndex: -1,
  },
  referralBoxInfo: {
    background: `url(${ReferralInfo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "90% 85%", sm: "cover", md: "100% 100%" },
    backgroundPosition: "center",
    margin: "0 auto",
    padding: "0.5em",
    marginBottom: { xs: "1em", sm: "1em", md: "14em", lg: "14em" },
    marginTop: { xs: "3em" },
    width: {
      xs: "100%",
      sm: "100%",
      md: "60%",
      lg: "60%",
      xl: "60%",
    },
  },
  referralBoxTitle: {
    fontWeight: 500,
    fontSize: { xs: "5.2vw", lg: "2.4vw" },
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    zIndex: 1,
    paddingTop: "1em",
    paddingLeft: { xs: "1em", lg: "0.5em" },
  },
  referralBoxTitleBold: {
    fontWeight: 700,
  },
  referralBoxSubTitle: {
    fontWeight: 700,
    fontSize: { xs: "5.4vw", lg: "2.6vw" },
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    paddingLeft: { xs: "1em", lg: "0.5em" },
  },
  faqTitle: {
    fontSize: { xs: "4vw", lg: "2vw" },
    fontWeight: 800,
    color: "rgba(255,255,255,1)",
    paddingTop: { xs: "1em" },
  },
  faqDesc: {
    fontSize: { xs: "2.5vw", lg: "1vw" },
    fontWeight: 500,
    color: "rgba(255,255,255,1)",
    marginBottom: "1em",
  },
  faqBox: {
    background:
      "95px 95px/calc(2*95px) calc(2*95px) conic-gradient(at calc(500%/6) 50%,#ffffff 25%,#0000 0),0 0/calc(2*95px) calc(2*95px) conic-gradient(at calc(500%/6) 50%,#ffffff 25%,#0000 0),32px 32px/calc(2*95px) calc(2*95px) conic-gradient(at calc(200%/3) 50%,#fbfbfb 25%,#0000 0),0 0/calc(2*32px) calc(2*32px) conic-gradient(at calc(200%/3) 50%,#fbfbfb 25%,#0000 0),repeating-conic-gradient(#f7f7f7 0 25%,#0000 0 50%) 0 0/calc(2*32px) calc(2*32px),linear-gradient(#f7f7f7 calc(100%/3),#fbfbfb 0 calc(200%/3),#ffffff 0) 0 0/32px 32px",
    width: "100%",
    height: { xs: "40vh", lg: "60vh" },
    borderRadius: "1em",
    overflow: "scroll",
  },
  faqBoxTitle: {
    fontSize: { xs: "3.1vw", lg: "1.2vw" },
    fontWeight: 600,
    color: "rgba(0,0,0,1)",
  },
  faqBoxDesc: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 500,
    color: "rgba(0,0,0,1)",
  },
  tableBodyCell: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgba(0,0,0,1)",
  },
  tableContainerStyle: {
    margin: "2em auto",
    width: "100%",
    borderRadius: "10px",
    backgroundImage: `url(${TableBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "110% 110%",
    backgroundPosition: "center",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  topRightBg: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  bottomRightBg: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  smartChainTitleText: {
    fontSize: "clamp(1.5rem, 2.5vw, 2rem)",
    color: "#3F3F42",
    fontWeight: 700,
    textAlign: "center",
  },
};
