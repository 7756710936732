import ComingSoonCard from "../../component/ComingSoonCard";

const { Box } = require("@mui/material");

const LeaderboardPage = () => {
  return (
    <Box>
      <ComingSoonCard title={"Leaderboard"} />
    </Box>
  );
};
export default LeaderboardPage;
