import dayjs from "dayjs";
import { ethers } from "ethers";

export const drawerWidth = 210;
export const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat().format(number);
  return formattedNumber;
};

export const convertToISO = (inputDate, inputTime) => {
  try {
    const dateTimeString = `${inputDate} ${inputTime}`;
    // const parsedDate = dayjs(dateTimeString, "YYYY-MM-DD hh:mm:ss A");
    const [date, time, meridian] = dateTimeString.split(" ");
    console.log("Date, time, meridian ", date, time, meridian);

    let [hours, minutes, seconds] = time.split(":").map(Number); // Extract time components

    // Adjust hours for AM/PM
    if (meridian === "PM" && hours < 12) {
      hours += 12; // Convert PM to 24-hour format
    } else if (meridian === "AM" && hours === 12) {
      hours = 0; // Handle 12 AM as 00
    }

    // Construct a new Date object
    const isoDateString = new Date(
      `${date}T${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}Z`
    ).toISOString();

    return isoDateString;
  } catch (error) {
    console.log("Error: ", error);
  }
};

function isColorDark(r, g, b) {
  // Calculate luminance using a standard formula
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 128; // Dark if luminance is below 128
}

export const getFontColorFromBackground = (imageUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = "Anonymous";

    img.onload = () => {
      // Create canvas to sample image pixels
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (!context) {
        resolve(false); // Fallback to dark font
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      // Get pixel data from the center of the image
      const { data } = context.getImageData(
        img.width / 2,
        img.height / 2,
        1,
        1
      );

      const [r, g, b] = data;
      resolve(isColorDark(r, g, b) ? true : false);
    };

    img.onerror = () => resolve(false); // Fallback in case of an error
  });
};

export const trimHash = (hash) => {
  if (!hash) return "";
  return `${hash.slice(0, 5)}...${hash.slice(-5)}`;
};

/**
 * Format a wallet address to display it in a shorter form.
 * @param {string} address the wallet address to format
 * @returns {string} a shorter version of the wallet address in the form XXXX....XXXX
 */
export const formatWalletAddress = (address) => {
  if (!address) return "";
  const firstPart = address.slice(0, 5);
  const lastPart = address.slice(-5);
  return `${firstPart}....${lastPart}`;
};

/**
 * Convert a date string into epoch time in seconds.
 * @param {string} dateString - The date string to convert.
 * @returns {number} - The epoch time in seconds.
 */
export const getEpochTimeSeconds = (dateString) => {
  const date = new Date(dateString);
  const epochTimeSeconds = Math.floor(date.getTime() / 1000);
  return epochTimeSeconds;
};

/**
 * Converts an epoch time in seconds to a datetime string in ISO format.
 * @param {BigInt|number} epochSeconds - The epoch time in seconds to convert.
 * @returns {string} - A datetime string in ISO format.
 */
export const getDateTimeFromEpoch = (epochSeconds) => {
  const epochInMilliseconds = Number(epochSeconds) * 1000;
  return new Date(epochInMilliseconds).toISOString();
};

/**
 * Converts an amount from Ether to Wei.
 * @param {number|string} amountEth - The amount in Ether to convert.
 * @returns {number} - The equivalent amount in Wei.
 */
export function etherToWei(amountEth) {
  return ethers.utils.parseUnits(amountEth.toString(), "ether");
}

/**
 * Converts an amount from Wei to Ether.
 * @param {BigInt|string} amountWei - The amount in Wei to convert.
 * @returns {string} - The equivalent amount in Ether, formatted as a string.
 */
export function weiToEther(amountWei) {
  return ethers.utils.formatEther(amountWei);
}

/**
 * Converts a hex value to a decimal value.
 * @param {string} hexValue - The hex value to convert.
 * @returns {number|null} - The decimal value, or null if the conversion fails.
 */
export const hexToDecimal = (hexValue) => {
  try {
    const bigInt = ethers.BigNumber.from(hexValue);
    const decimalValue = ethers.BigNumber.from(bigInt);
    return decimalValue;
  } catch (error) {
    console.error("Error converting hex to decimal:", error);
    return null;
  }
};

/**
 * Converts a value to an ethers.BigNumber.
 * 
 * @param {string | number | bigint} value - The value to convert.
 * @returns {ethers.BigNumber} - The corresponding BigNumber.
 * @throws {Error} - Throws if the value cannot be converted.
 */
export const toBigNumber = (value) => {
  try {
    return ethers.BigNumber.from(value);
  } catch (error) {
    console.error("Error converting to BigNumber:", error);
    throw new Error("Invalid value for BigNumber conversion.");
  }
};