import theme from "../../theme/Theme";

const BottomRight = ({ width = 339, height = 278 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 339 278" fill="none">
      <path d="M339 41L103 278H339V41Z" fill={theme.palette.primary.dark} />
      <path
        d="M130 251L103 278H155L184 251H130Z"
        fill={theme.custom.primaryDarker}
      />
      <path
        d="M27 251L0 278H103L130 251H27Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M339 0L89.5 251H130L339 41.0818V0Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
export default BottomRight;
