import { FriendsFamilyDraw, BnbWeeklyDraw, ComingSoon, SwssCommunityDraw, SwssWeeklyThumb, SwssMonthlyDraw } from "../assets";

export const DrawsList = [
  {
    id: "1",
    title: "SWSS Friends and Family",
    bannerImage: `${FriendsFamilyDraw}`,
    totalPrice: "1480000",
    firstWinner: "100000",
    totalWinners: "99550",
    startDate: "2024-11-10",
    startTime: "05:00:00 am",
    endDate: "2024-12-31",
    endTime: "11:55:00 pm",
    winingProbability: "49%",
    totalTickets: "200000",
    sold: "0",
    pricePerTicket: "10",
    currencyTicker: "USDT",
    blockchain: "bscMainnet",
    tokenContractAddress: "0x55d398326f99059fF775485246999027B3197955",
    trending: true,
  },
  {
    id: "2",
    title: "Binance Weekly",
    bannerImage: `${BnbWeeklyDraw}`,
    totalPrice: "1480000",
    firstWinner: "100000",
    totalWinners: "99552",
    startDate: "2024-11-10",
    startTime: "05:00:00 am",
    endDate: "2024-12-31",
    endTime: "11:55:00 pm",
    winingProbability: "49%",
    totalTickets: "200000",
    sold: "0",
    pricePerTicket: "10",
    currencyTicker: "USDT",
    blockchain: "bscMainnet",
    tokenContractAddress: "0x55d398326f99059fF775485246999027B3197955",
    trending: true,
  },
  {
    id: "3",
    title: "SWSS Christmas",
    bannerImage:
      "https://media.istockphoto.com/id/1344645608/vector/christmas-greeting-cards.jpg?s=612x612&w=0&k=20&c=e2aNQTelcWY08bXOxrtliMz5hZBKCZ02EH67MCsPUUA=",
    totalPrice: "1480000",
    firstWinner: "100000",
    totalWinners: "45670",
    startDate: "2024-12-10",
    startTime: "05:30:00 am",
    endDate: "2024-12-31",
    endTime: "11:30:00 pm",
    winingProbability: "45%",
    totalTickets: "10000",
    sold: "0",
    pricePerTicket: "20",
    currencyTicker: "USDT",
    blockchain: "bscMainnet",
    tokenContractAddress: "0x55d398326f99059fF775485246999027B3197955",
    trending: true,
  },
  {
    id: "4",
    title: "SWSS New Year 2025",
    bannerImage: `https://t4.ftcdn.net/jpg/01/76/79/99/360_F_176799923_ChXFBBm45gojI8PDfFBce7YlAWqYEtbH.jpg`,
    totalPrice: "2220000",
    firstWinner: "90000",
    totalWinners: "51479",
    startDate: "2024-12-10",
    startTime: "05:30:00 am",
    endDate: "2024-12-31",
    endTime: "11:30:00 pm",
    winingProbability: "51%",
    totalTickets: "100000",
    sold: "0",
    pricePerTicket: "30",
    currencyTicker: "USDT",
    blockchain: "bscMainnet",
    tokenContractAddress: "0x55d398326f99059fF775485246999027B3197955",
    trending: true,
  },
  {
    id: "101",
    title: "SWSS Community Draw",
    bannerImage: `${SwssCommunityDraw}`,
    totalPrice: "16500000",
    firstWinner: "1500000",
    totalWinners: "18683",
    startDate: "2025-01-29",
    startTime: "12:01:00 am",
    endDate: "2025-02-07",
    endTime: "11:30:00 pm",
    winingProbability: "18%",
    totalTickets: "100000",
    sold: "0",
    pricePerTicket: "300",
    currencyTicker: "SWSS",
    blockchain: "swssMainnet",
    tokenContractAddress: "0x93b564b4F57F9f9615408898362DD8dBaB4d6160",
    trending: true,
  },
  {
    id: "102",
    title: "SWSS Weekly Draw",
    bannerImage: `${SwssWeeklyThumb}`,
    totalPrice: "2750000",
    firstWinner: "250000",
    totalWinners: "16654",
    startDate: "2025-01-29",
    startTime: "12:01:00 am",
    endDate: "2025-02-07",
    endTime: "11:30:00 pm",
    winingProbability: "16%",
    totalTickets: "100000",
    sold: "0",
    pricePerTicket: "50",
    currencyTicker: "SWSS",
    blockchain: "swssMainnet",
    tokenContractAddress: "0x93b564b4F57F9f9615408898362DD8dBaB4d6160",
    trending: true,
  },
  {
    id: "103",
    title: "SWSS Monthly Draw",
    bannerImage: `${SwssMonthlyDraw}`,
    totalPrice: "5500000",
    firstWinner: "500000",
    totalWinners: "21040",
    startDate: "2025-01-29",
    startTime: "12:01:00 am",
    endDate: "2025-02-07",
    endTime: "11:30:00 pm",
    winingProbability: "21%",
    totalTickets: "100000",
    sold: "0",
    pricePerTicket: "100",
    currencyTicker: "SWSS",
    blockchain: "swssMainnet",
    tokenContractAddress: "0x93b564b4F57F9f9615408898362DD8dBaB4d6160",
    trending: true,
  },
];
