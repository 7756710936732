import React, { useState, useEffect } from "react";
import {
  fetchDirectReferrer,
  generateReferral,
  fetchReferrals,
} from "../../../utils/referralUtils";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styles } from "../../home/styles";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import NoDataFound from "../../../assets/svg/NoDataFound";
import theme from "../../../theme/Theme";

const ReferralDisplay = ({ contractAddress, abi, userAddress }) => {
  const [directReferrer, setDirectReferrer] = useState(null);
  const [referralChain, setReferralChain] = useState([]);
  const [referralLink, setReferralLink] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      const address = Cookies.get("connectedAddress");
      const referrers = await fetchReferrals(address);
      // console.log("referrred", referrers);
      const referrer = await fetchDirectReferrer();
      setDirectReferrer(referrer);

      // const chain = await fetchReferralChain();
      setReferralChain(referrers);
    };

    const address = Cookies.get("connectedAddress");
    if (address) {
      const link = generateReferral(address);
      setReferralLink(link);
    }

    fetchData();
  }, [contractAddress, abi, userAddress]);

  const handleCopy = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink).then(() => {
        toast.success("Referral link copied to clipboard!", {
          style: { fontSize: "14px" },
        });
      });
    } else {
      toast.error("Referral link is not available.", {
        style: { fontSize: "14px" },
      });
    }
  };

  const handleReferrerCopy = () => {
    if (directReferrer) {
      navigator.clipboard.writeText(directReferrer).then(() => {
        toast.success("Referrer copied to clipboard!", {
          style: { fontSize: "14px" },
        });
      });
    } else {
      toast.error("Referrer is not available.", {
        style: { fontSize: "14px" },
      });
    }
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={2}
      >
        <DashboardPageTitle title={"My Referrals"} />
      </Stack>

      <Card component={Paper}>
        <CardContent>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Box sx={{ width: { xs: "100%", lg: "49%" } }}>
              <Typography sx={style.titleText}>My Referral Link</Typography>
              <Box
                sx={{
                  backgroundColor: theme.custom.primaryLighter,
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "0.8rem", lg: "1rem" },
                    fontWeight: 600,
                    color: "#000",
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {referralLink}
                </Typography>
                <Tooltip title="Copy to clipboard" arrow>
                  <IconButton sx={style.copyButton} onClick={handleCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {isMobile ? (
              <></>
            ) : (
              <Divider
                orientation="vertical"
                sx={{ width: "1px", height: "3em" }}
              />
            )}

            <Box sx={{ width: { xs: "100%", lg: "49%" } }}>
              <Typography sx={style.titleText}>My Referrer</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 0px",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "0.8rem", lg: "1rem" },
                    fontWeight: 600,
                    color: "#000",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {directReferrer}
                </Typography>
                <Tooltip title="Copy to clipboard" arrow>
                  <IconButton
                    sx={style.copyButton}
                    onClick={handleReferrerCopy}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      {/* Display Referral Chain */}

      <Card component={Paper} sx={{ mt: 2 }}>
        <CardHeader
          title={"My Referral Levels"}
          titleTypographyProps={{
            style: {
              fontSize: { xs: "3.4vw", lg: "1.2vw" },
              fontWeight: 600,
              color: "rgba(64,64,64,1)",
            },
          }}
        ></CardHeader>
        <CardContent>
          <TableContainer
            component={Paper}
            sx={{
              ...styles.tableContainerStyle,
              width: { lg: "10  0%", sx: "100%" },
              margin: "0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeadCell}>SERIAL NO.</TableCell>
                  <TableCell sx={styles.tableHeadCell}>
                    REFERRER ADDRESS
                  </TableCell>
                  <TableCell sx={styles.tableHeadCell}>LEVEL</TableCell>{" "}
                  {/* New Level Column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {referralChain.length > 0 ? (
                  referralChain.map((referrer, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}
                    >
                      <TableCell sx={styles.tableBodyCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {referrer.referee}
                      </TableCell>{" "}
                      {/* Access the referee address */}
                      <TableCell sx={styles.tableBodyCell}>
                        {referrer.level}
                      </TableCell>{" "}
                      {/* Display the level */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <NoDataFound />
                      <Typography
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          padding: "2em",
                        }}
                      >
                        No referral level data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReferralDisplay;

const style = {
  myTicket: {
    fontSize: { xs: "1.8rem", lg: "2.4rem" },
    fontWeight: 700,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  titleText: {
    fontSize: { xs: "3.4vw", lg: "1.2vw" },
    fontWeight: 600,
    color: "rgba(64,64,64,1)",
    marginBottom: "0.5em",
  },
  directReferrerContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "0.6em",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
  },
  directReferrerText: {
    fontSize: { xs: "0.6rem", lg: "1.2rem" },
    fontWeight: 600,
    color: "#000",
    textAlign: "center",
  },
  copyButton: {
    color: theme.palette.primary.main,
    marginLeft: 1,
    borderRadius: "50%",
  },
};
