import { Box } from "@mui/material";
import ComingSoonCard from "../../component/ComingSoonCard";

const TestimonialsPage = () => {
  return (
    <Box>
      <ComingSoonCard title={"Testimonials"} />
    </Box>
  );
};
export default TestimonialsPage;
