import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import { ClockIcon, TrophyIcon } from "@heroicons/react/24/outline";
import { OvalBg } from "../../../assets";
import { useState, useEffect } from "react";
import { CustomerData } from "../data/CustomerData";
import { DrawsList } from "../../../data/DrawsList";
import dayjs from "dayjs";
import { convertToISO, formatNumber } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { fetchParticipantCount } from "../../../utils/drawDetailsUtils";
import { blockchainNetworks } from "../../../config/blockchain";
import theme from "../../../theme/Theme";
import AOS from "aos";
import "aos/dist/aos.css";
import ErrorBoundary from "../../../component/ErrorBoundary";

const ActiveCompetitions = () => {
  const [activeCustomer, setActiveCustomer] = useState(1);
  const navigate = useNavigate();
  const [soldTickets, setSoldTickets] = useState({});

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const activeOtherChains = DrawsList.filter(
    (draw) =>
      new Date(draw.endDate) > new Date() &&
      draw.blockchain !== "swssMainnet" &&
      draw.trending === true
  ).slice(0, 3);

  const activeSwssChains = DrawsList.filter(
    (draw) =>
      new Date(draw.endDate) > new Date() &&
      draw.blockchain === "swssMainnet" &&
      draw.trending === true
  ).slice(0, 3);

  const dayRemaining = (date, time) => {
    const inputDate = convertToISO(date, time);
    const targetDate = dayjs(inputDate);
    const today = dayjs();
    const differenceInDays = targetDate.diff(today, "day");
    return differenceInDays;
  };

  const getSoldTicketPercentages = (soldTickets, totalTickets) => {
    const soldPercentages = (soldTickets / totalTickets) * 100;
    return soldPercentages;
  };
  const handleCompetitionViewMoreClicked = () => {
    navigate("/draw");
  };

  const handleTestimonialViewMoreClicked = () => {
    navigate("/testimonials");
  };

  const fetchSoldTickets = async (drawId, blockchain) => {
    try {
      const response = await fetchParticipantCount(drawId, blockchain);
      setSoldTickets((prevState) => ({
        ...prevState,
        [drawId]: response,
      }));
    } catch (error) {
      console.error("Error fetching sold tickets:", error);
    }
  };

  useEffect(() => {
    DrawsList.forEach((item) => {
      fetchSoldTickets(item.id, item.blockchain);
    });
  }, []);

  const getBlockchainIcon = (blockchain) => {
    return blockchainNetworks[blockchain].iconUrl;
  };

  return (
    <ErrorBoundary>
      <Box
        sx={{
          position: "relative",
          top: { xs: -30, sm: -30, md: -50, lg: -50 },
        }}
      >
        <div style={styles.activeCompetitions}>
          <img
            src={OvalBg}
            style={styles.activeCompetitionBg}
            alt="oval_image"
          />
          <Typography sx={styles.titleText}>
            Trending <span style={styles.titleHighLight}>Competitions</span>
          </Typography>
          <Typography
            sx={{
              ...styles.subTitleText,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            Explore the top trending draws and get your chance to win big with
            these exciting ongoing competitions
          </Typography>
          <Divider
            flexItem
            textAlign="center"
            sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 2 } }}
          >
            <Typography sx={styles.smartChainTitleText}>
              BNB Smart Chain
            </Typography>
          </Divider>
          <Grid2
            container
            spacing={{ xs: 4, md: 2 }}
            pt={{ xs: 4, md: 2 }}
            px={{ xs: 2, sm: 2, md: 5, lg: 15, xl: 15 }}
            alignItems="center"
            justifyContent="center"
          >
            {activeOtherChains.map((item, index) => (
              <Grid2
                item
                size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                key={index}
                onClick={() => {
                  navigate(`/draw/${item.id}`);
                }}
                sx={{
                  cursor: "pointer",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Box data-aos="zoom-in-up" sx={styles.competitionCard}>
                  <Box sx={styles.imageContainer}>
                    <img
                      src={item.bannerImage}
                      style={styles.competitionCardImage}
                      alt="epic_win"
                    />
                    <Box sx={styles.titleContainer}>
                      <Typography sx={styles.competitionCardTitle}>
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={styles.competitionCardContent}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mx={2}
                      sx={{
                        borderLeft: `4px solid ${theme.palette.primary.main}`,
                      }}
                      gap={1}
                    >
                      <Box>
                        <Typography sx={styles.totalPoolPriceText}>
                          Total Pool Prize
                        </Typography>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={0.5}
                        >
                          <Typography sx={styles.totalPoolPriceTicker}>
                            {item.currencyTicker}
                          </Typography>
                          <Typography sx={styles.totalPoolPriceValueText}>
                            {formatNumber(item.totalPrice)}
                          </Typography>
                        </Stack>
                      </Box>

                      <img
                        src={getBlockchainIcon(item.blockchain)}
                        alt="eth"
                        width={24}
                        height={24}
                        style={styles.blockchainIcon}
                      />
                    </Stack>

                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={{ xs: 2, sm: 2, md: 1, lg: 2, xl: 2 }}
                      mt={4}
                      mx={2}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={styles.otherTitleText}>
                          1st Winner
                        </Typography>
                        <Typography sx={styles.otherPriceValueText}>
                          {item.currencyTicker} {formatNumber(item.firstWinner)}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ ...styles.otherTitleText, textAlign: "right" }}
                        >
                          Total Winners
                        </Typography>
                        <Typography
                          sx={{
                            ...styles.otherPriceValueText,
                            textAlign: "right",
                          }}
                        >
                          {item.totalWinners}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"center"}
                      mt={3}
                      mx={2}
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <ClockIcon width={24} />
                        <Typography sx={styles.daysText}>
                          {`${dayRemaining(
                            item.endDate,
                            item.endTime
                          )} Days Remaining`}
                        </Typography>
                      </Stack>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ backgroundColor: "rgba(82, 82, 82, 1)" }}
                      />
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <TrophyIcon width={24} />
                        <Typography sx={styles.daysText}>
                          {`${item.winingProbability} Winning Probability`}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Box mx={2} my={3}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        mb={0.3}
                      >
                        <Typography sx={styles.progressText}>
                          {`Sold: ${soldTickets[item.id] || 0}`}
                        </Typography>
                        <Typography sx={styles.progressText}>
                          {`Remaining: ${formatNumber(
                            item.totalTickets - (soldTickets[item.id] || 0)
                          )}`}
                        </Typography>
                      </Stack>
                      <Box sx={styles.progressBarContainer}>
                        <Box
                          sx={{
                            ...styles.soldTickers,
                            width: `${getSoldTicketPercentages(
                              soldTickets[item.id] || 0,
                              item.totalTickets
                            )}%`,
                          }}
                        ></Box>
                      </Box>
                    </Box>

                    <Box sx={styles.pricePerTicket}>
                      <Typography sx={styles.joinButtonText}>
                        Price / Ticket{" "}
                        <span style={{ fontWeight: 700 }}>
                          {item.currencyTicker} {item.pricePerTicket}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
            ))}
          </Grid2>
          {activeSwssChains?.length > 0 && (
            <>
              <Divider
                flexItem
                textAlign="center"
                sx={{
                  mt: 4,
                  "&::before, &::after": {
                    borderColor: "white", // Customize the divider line color
                  },
                }}
              >
                <Typography
                  sx={{
                    ...styles.smartChainTitleText,
                    color: theme.custom.white,
                  }}
                >
                  SWSS Smart Chain
                </Typography>
              </Divider>
              <Grid2
                container
                spacing={{ xs: 4, md: 2 }}
                pt={{ xs: 4, md: 2 }}
                px={{ xs: 2, sm: 2, md: 5, lg: 15, xl: 15 }}
                alignItems="center"
                justifyContent="center"
              >
                {activeSwssChains.map((item, index) => (
                  <Grid2
                    item
                    size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                    key={index}
                    onClick={() => {
                      navigate(`/draw/${item.id}`);
                    }}
                    sx={{
                      cursor: "pointer",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <Box data-aos="zoom-in-up" sx={styles.competitionCard}>
                      <Box sx={styles.imageContainer}>
                        <img
                          src={item.bannerImage}
                          style={styles.competitionCardImage}
                          alt="epic_win"
                        />
                        <Box sx={styles.titleContainer}>
                          <Typography sx={styles.competitionCardTitle}>
                            {item.title}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={styles.competitionCardContent}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          mx={2}
                          sx={{
                            borderLeft: `4px solid ${theme.palette.primary.main}`,
                          }}
                          gap={1}
                        >
                          <Box>
                            <Typography sx={styles.totalPoolPriceText}>
                              Total Pool Prize
                            </Typography>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={0.5}
                            >
                              <Typography sx={styles.totalPoolPriceTicker}>
                                {item.currencyTicker}
                              </Typography>
                              <Typography sx={styles.totalPoolPriceValueText}>
                                {formatNumber(item.totalPrice)}
                              </Typography>
                            </Stack>
                          </Box>

                          <img
                            src={getBlockchainIcon(item.blockchain)}
                            alt="eth"
                            width={24}
                            height={24}
                            style={styles.blockchainIcon}
                          />
                        </Stack>

                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          spacing={{ xs: 2, sm: 2, md: 1, lg: 2, xl: 2 }}
                          mt={4}
                          mx={2}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Typography sx={styles.otherTitleText}>
                              1st Winner
                            </Typography>
                            <Typography sx={styles.otherPriceValueText}>
                              {item.currencyTicker}{" "}
                              {formatNumber(item.firstWinner)}
                            </Typography>
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              sx={{
                                ...styles.otherTitleText,
                                textAlign: "right",
                              }}
                            >
                              Total Winners
                            </Typography>
                            <Typography
                              sx={{
                                ...styles.otherPriceValueText,
                                textAlign: "right",
                              }}
                            >
                              {item.totalWinners}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems={"center"}
                          mt={3}
                          mx={2}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <ClockIcon width={24} />
                            <Typography sx={styles.daysText}>
                              {`${dayRemaining(
                                item.endDate,
                                item.endTime
                              )} Days Remaining`}
                            </Typography>
                          </Stack>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ backgroundColor: "rgba(82, 82, 82, 1)" }}
                          />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <TrophyIcon width={24} />
                            <Typography sx={styles.daysText}>
                              {`${item.winingProbability} Winning Probability`}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Box mx={2} my={3}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={0.3}
                          >
                            <Typography sx={styles.progressText}>
                              {`Sold: ${soldTickets[item.id] || 0}`}
                            </Typography>
                            <Typography sx={styles.progressText}>
                              {`Remaining: ${formatNumber(
                                item.totalTickets - (soldTickets[item.id] || 0)
                              )}`}
                            </Typography>
                          </Stack>
                          <Box sx={styles.progressBarContainer}>
                            <Box
                              sx={{
                                ...styles.soldTickers,
                                width: `${getSoldTicketPercentages(
                                  soldTickets[item.id] || 0,
                                  item.totalTickets
                                )}%`,
                              }}
                            ></Box>
                          </Box>
                        </Box>

                        <Box sx={styles.pricePerTicket}>
                          <Typography sx={styles.joinButtonText}>
                            Price / Ticket{" "}
                            <span style={{ fontWeight: 700 }}>
                              {item.currencyTicker} {item.pricePerTicket}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid2>
                ))}
              </Grid2>
            </>
          )}

          {/* <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={1}
          mt={3}
          mx={1}
          mr={10}
        >
          {/* <Button
            variant="contained"
            sx={styles.viewMoreButton}
            onClick={handleCompetitionViewMoreClicked}
          >
            View More
          </Button> }
        </Stack> */}

          {/*
           *** Happy Customer section starts here ***
           */}
          <Box sx={{ margin: "2em 0 1em 3em" }}>
            <Typography sx={styles.happyCustomerTitle}>
              Happy <span style={styles.happyCustomerHighLight}>Customers</span>
            </Typography>
            <Typography sx={styles.happyCustomerSubTitle}>
              See what our users have to say about their exciting experiences
              with SwssDraw!
            </Typography>
          </Box>

          <Box sx={styles.customerCardHolder}>
            {CustomerData.map((customer, index) => (
              <Card
                key={index}
                data-aos="flip-up"
                sx={{
                  ...styles.happyCustomerCard,
                  "&:hover": {
                    transform: {
                      xs: "scale(1)",
                      sm: "scale(1)",
                      md:
                        activeCustomer !== index ? "scale(1.3)" : "scale(1.3)",
                      lg:
                        activeCustomer !== index ? "scale(1.3)" : "scale(1.3)",
                    },
                  },
                  transform: {
                    xs: "scale(1)",
                    sm: "scale(1)",
                    md: activeCustomer === index ? "scale(1.3)" : "scale(1)",
                    lg: activeCustomer === index ? "scale(1.3)" : "scale(1)",
                  },
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Typography sx={styles.customerName}>
                    {customer.name}
                  </Typography>
                  <Typography sx={styles.customerDesignation}>
                    {customer.title}
                  </Typography>
                  <Typography sx={styles.customerDescription}>
                    {customer.comment}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={1}
            mt={3}
            mx={1}
            mr={10}
            pb={10}
          >
            {/* <Button
            variant="contained"
            sx={styles.viewMoreButton}
            onClick={handleTestimonialViewMoreClicked}
          >
            View More
          </Button> */}
          </Stack>
        </div>
      </Box>
    </ErrorBoundary>
  );
};
export default ActiveCompetitions;
