import { Box, Typography, Link, List, ListItem } from "@mui/material";

const DisclaimerPage = () => {
  return (
    <Box p={4}>
      <Typography variant="h2" gutterBottom>
        Disclaimer
      </Typography>
      <Typography paragraph>
        SwssDraw is committed to providing a fair, transparent, and decentralized experience for all participants. Please read the following details carefully before participating in any draw:
      </Typography>

      <Typography variant="h6" gutterBottom>
        Blockchain-Based Draw
      </Typography>
      <Typography paragraph>
        All SwssDraw competitions operate entirely on blockchain technology, ensuring transparency and fairness. The selection of winners is managed solely by the deployed smart contract, over which neither SwssDraw nor KittyDraw (our technology partner) has any control. You can review the smart contract source code{" "}
        <Link href="#" target="_blank" rel="noopener">
          here
        </Link>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Reward Distribution
      </Typography>
      <Typography paragraph>
        The reward amounts displayed on draw pages are based on the assumption of 100% ticket sales. If all tickets are not sold, the allotted/collected amount will still be distributed among participants according to the following rules:
      </Typography>

      <List>
        <ListItem>
          <Typography>
            <strong>Top 3 Level Winners:</strong> A percentage of the collected pool amount will be allocated to the first three levels of winners.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>Remaining Levels:</strong> Fixed-price rewards will be given to subsequent levels until the total allotted amount is exhausted.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        Transparency and Fairness
      </Typography>
      <Typography paragraph>
        SwssDraw leverages blockchain technology to maintain transparency and trust. All draws, participation, and reward distributions are recorded on the blockchain and can be verified by participants.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes and Updates
      </Typography>
      <Typography paragraph>
        SwssDraw reserves the right to make changes to the reward distribution structure based on participation levels. Any changes will be implemented in alignment with the terms outlined by the smart contract.
      </Typography>

      <Typography paragraph>
        For further questions, please contact our support team at{" "}
        <Link href="mailto:support@swss.io">support@swss.io</Link>.
      </Typography>
    </Box>
  );
};

export default DisclaimerPage;
