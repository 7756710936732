import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebaseConfig";

const logError = (error) => {
  logEvent(analytics, "exception", {
    description: error.message,
    fatal: true,
  });
};

export default logError;
