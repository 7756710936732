import React, { useState } from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import { formatWalletAddress } from "../utils/common";
import { getWalletImage } from "../service/WalletConnection";
import { useNavigate } from "react-router-dom";
import theme from "../theme/Theme";

const WalletInfo = ({
  connectedWalletType,
  connectedAddress,
  onDisconnect,
}) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleRedirect = () => {
    navigate("/dashboard/tickets");
  };

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Stack
        direction="column"
        alignItems="center"
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
        sx={{ position: "relative" }}
      >
        <Stack direction="row" alignItems="center" sx={styles.walletContainer}>
          <img
            src={getWalletImage(connectedWalletType)}
            alt={connectedWalletType}
            width="25"
          />
          <Typography sx={styles.smallText}>
            {formatWalletAddress(connectedAddress)}
          </Typography>
        </Stack>

        {/* Floating Dropdown with Disconnect Button */}
        {showDropdown && (
          <Box sx={styles.dropdownMenu}>
            <Button
              size="medium"
              variant="contained"
              sx={styles.buttonContainer}
              onClick={onDisconnect}
            >
              Disconnect
            </Button>
          </Box>
        )}
      </Stack>

      <Box sx={styles.buttonWrapper} onClick={handleRedirect}>
        <Box sx={styles.buttonContainer}>
          <Typography sx={styles.buttonText}>Dashboard</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

const styles = {
  walletContainer: {
    margin: "10px",
    padding: { xs: "4px", lg: "8px" },
    borderRadius: "8px",
    bgcolor: "#f5f5f5",
    position: "relative",
    transition: "all 0.3s ease",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    },
  },
  smallText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "#000",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    transition: "opacity 0.3s ease",
    zIndex: 1,
    background: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  buttonWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: { xs: "0.1em", lg: "0.15em" },
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    padding: { xs: "0.3em 0.3em", lg: "0.3em 1em" },
    borderRadius: "10px",
  },
  buttonText: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
  },
};

export default WalletInfo;
