import { Box, Typography } from "@mui/material";
import { styles } from "../styles";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../customdot.css";
import { CarouselData } from "../data/CarouselData";
import { useNavigate } from "react-router-dom";

const HomeCarousel = () => {
  const navigate = useNavigate();
  const NextArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow next" onClick={onClick}>
        <ChevronRightIcon width={{ xs: 16, sm: 16, md: 22, lg: 22, xl: 22 }} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow prev" onClick={onClick}>
        <ChevronLeftIcon width={{ xs: 16, sm: 16, md: 22, lg: 22, xl: 22 }} />
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    dotsClass: "slick-dots custom-dots", // Use a custom class for the dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrow: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleParticipateClick = () => {
    navigate("/draw");
  };

  return (
    <section style={styles.carouselStyle}>
      {/* <Slider {...sliderSettings}>
        {CarouselData.map((item, index) => (
          <Box sx={styles.carouselBg} key={index}>
            <Box sx={styles.carouselDescription}>
              <Typography sx={styles.heading1}>{item.title1}</Typography>
              <Typography sx={styles.heading2}>{item.title2}</Typography>
              <Typography sx={styles.heading3}>{item.title3}</Typography>
              <Typography sx={styles.description}>
                {item.description}
              </Typography>
            </Box>
            <Box sx={styles.buttonWrapper} onClick={handleParticipateClick}>
              <Box sx={styles.buttonContainer}>
                <Typography sx={styles.buttonText}>Participate NOW</Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider> */}
      <Box sx={styles.carouselBg}>
        <Box sx={styles.carouselDescription}>
          <Typography sx={styles.heading1}>{CarouselData[0].title1}</Typography>
          <Typography sx={styles.heading2}>{CarouselData[0].title2}</Typography>
          <Typography sx={styles.heading3}>{CarouselData[0].title3}</Typography>
          <Typography sx={styles.description}>
            {CarouselData[0].description}
          </Typography>
        </Box>
        <Box sx={styles.buttonWrapper} onClick={handleParticipateClick}>
          <Box sx={styles.buttonContainer}>
            <Typography sx={styles.buttonText}>Participate NOW</Typography>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default HomeCarousel;
