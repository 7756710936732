import React from "react";
import AppRoutes from "./AppRoutes";
import theme from "./theme/Theme";
import { css, CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import CookieConsentBanner from "./component/CookieConsentBanner";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsentBanner />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        toastClassName={css({ fontFamily: '"Proxima Nova", sans-serif' })}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
