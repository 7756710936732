const OrderProceed = ({
  width = 32,
  height = 32,
  color1 = "#b91321",
  color2 = "#ff8f8f",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32">
      <path
        fill={color1}
        d="M15,30H3a3,3,0,0,1-3-3V1A1,1,0,0,1,.62.08,1,1,0,0,1,1.71.29L3.29,1.88a1,1,0,0,0,1.42,0L6.29.29a1,1,0,0,1,1.42,0L9.29,1.88a1,1,0,0,0,1.42,0L12.29.29a1,1,0,0,1,1.42,0l1.58,1.59a1,1,0,0,0,1.42,0L18.29.29a1,1,0,0,1,1.42,0l1.58,1.59a1,1,0,0,0,1.42,0L24.29.29A1,1,0,0,1,25.38.08,1,1,0,0,1,26,1V15a1,1,0,0,1-2,0V3.41a3.07,3.07,0,0,1-4.12-.12L19,2.41l-.88.88a3.06,3.06,0,0,1-4.24,0L13,2.41l-.88.88a3.06,3.06,0,0,1-4.24,0L7,2.41l-.88.88A3.07,3.07,0,0,1,2,3.41V27a1,1,0,0,0,1,1H15a1,1,0,0,1,0,2Z"
      />
      <path fill={color2} d="M15,25H6a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z" />
      <path fill={color2} d="M15,21H6a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z" />
      <path fill={color2} d="M17,17H6a1,1,0,0,1,0-2H17a1,1,0,0,1,0,2Z" />
      <path fill={color2} d="M20,13H6a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z" />
      <path fill={color2} d="M20,9H6A1,1,0,0,1,6,7H20a1,1,0,0,1,0,2Z" />
      <path
        fill={color2}
        d="M23,19h4a0,0,0,0,1,0,0v3a1,1,0,0,1-1,1H24a1,1,0,0,1-1-1V19A0,0,0,0,1,23,19Z"
      />
      <path
        fill={color1}
        d="M29,32H21a3,3,0,0,1-3-3V21a3,3,0,0,1,3-3h8a3,3,0,0,1,3,3v8A3,3,0,0,1,29,32ZM21,20a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1Z"
      />
      <path fill={color1} d="M24,28H23a1,1,0,0,1,0-2h1a1,1,0,0,1,0,2Z" />
    </svg>
  );
};
export default OrderProceed;
