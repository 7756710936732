import React, { useState, useEffect } from "react";
import { fetchUserTickets } from "../../../utils/transactionsUtils";
import Cookies from "js-cookie"; // Assuming the connected wallet is stored in cookies
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import DashboardLoader from "../../../component/DashboardLoader";
import { trimHash } from "../../../utils/common";
import { iSOToDateTime } from "../../../utils/dateUtils";
import { DrawsList } from "../../../data/DrawsList";
import { blockchainNetworks } from "../../../config/blockchain";
import theme from "../../../theme/Theme";
import commonStyle from "../common.style";

const MyTransactionsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Get the connected user's address from cookies
    const connectedAddress = Cookies.get("connectedAddress");

    if (connectedAddress) {
      setLoading(true);
      fetchUserTickets(connectedAddress)
        .then((data) => {
          // console.log("Data: ", data);

          setTickets(data);
        })
        .catch((err) => {
          console.error(err);
          setError("Failed to fetch tickets. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const getTransactionUrl = (drawId, transactionHash) => {
    const list = DrawsList.find((draw) => draw.id === drawId);
    return `${
      blockchainNetworks[list.blockchain].explorerBaseUrl
    }/tx/${transactionHash}`;
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DashboardPageTitle title={"My Tickets Transactions"} />
      </Stack>
      {loading ? (
        <DashboardLoader isLoading={loading} />
      ) : (
        <TableContainer component={Paper} sx={commonStyle.tableBackground}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.custom.tableHeaderBg }}>
                <TableCell sx={commonStyle.ticketTitleStyle}>#</TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>Draw ID</TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Ticket Count
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Total Amount
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Transaction Date
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Transaction Hash
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets?.length > 0 ? (
                tickets.map((ticket, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        index % 2 !== 0 ? "#f3f3f3" : "transparent",
                    }}
                  >
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {ticket.drawId}
                    </TableCell>

                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {ticket.ticketCount}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {ticket.totalAmount}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {iSOToDateTime(ticket.transactionDate)}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      <a
                        href={getTransactionUrl(
                          ticket.drawId,
                          ticket.transactionHash
                        )}
                        style={commonStyle.transactionLinkStyle}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {trimHash(ticket.transactionHash)}
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    <Typography sx={commonStyle.noTransactions}>
                      No Transactions Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MyTransactionsPage;
