export const FAQData = [
  {
    title: "What is SwssDraw?",
    description:
      "SwssDraw is the world's first blockchain-based lucky draw platform, where every competition is powered by smart contracts, ensuring transparency, fairness, and security in the prize distribution.",
  },
  {
    title: "How can I participate in a draw?",
    description:
      "To participate, simply log in with a decentralized wallet like MetaMask or TronLink, select your draw, choose your number, and make a payment using your wallet. You'll receive a unique NFT ticket with your coupon number.",
  },
  {
    title: "Is the draw process fair?",
    description:
      "Yes! Our draws are powered by blockchain technology and smart contracts, ensuring the results are random, transparent, and tamper-proof. The winner is determined fairly with no human intervention.",
  },
  {
    title: "How do I receive my rewards?",
    description:
      "If you win, the reward will be automatically sent to your blockchain wallet address, ensuring a secure and seamless experience. There's no manual claim process - it's all automated.",
  },
  {
    title: "What blockchain wallets are supported?",
    description:
      "SwssDraw supports popular decentralized wallets such as MetaMask and TronLink. You can easily connect your wallet to participate in draws and receive rewards.",
  },
  {
    title: "Are the competitions transparent?",
    description:
      "Absolutely! All competitions, results, and transactions are recorded on the blockchain, providing full transparency for every participant. You can verify every draw outcome.",
  },
  {
    title: "Can I participate in multiple draws?",
    description:
      "Yes! You can participate in as many draws as you like, as long as you meet the requirements for each competition. More draws, more chances to win!",
  },
];
