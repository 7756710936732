import theme from "../../theme/Theme";

const TopLeft = ({ width = 398, height = 398 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 398 398" fill="none">
      <path d="M0 398V0H397.5L0 398Z" fill={theme.palette.primary.light} />
      <path d="M0 72V0H72L0 72Z" fill={theme.palette.primary.dark} />
      <path
        d="M111.5 0L0 118.5V190L189.5 0H111.5Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
export default TopLeft;
