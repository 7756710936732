import { Typography } from "@mui/material";
import theme from "../theme/Theme";

const DashboardPageTitle = ({ title }) => {
  return <Typography sx={styles.titleText}>{title}</Typography>;
};
export default DashboardPageTitle;
const styles = {
  titleText: {
    fontSize: { xs: "1.8rem", lg: "2rem" },
    fontWeight: 700,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
};
