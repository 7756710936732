import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import { fetchWinners } from "../../../utils/winnersUtils"; // Updated to use fetchWinners
import { formatNumber } from "../../../utils/common";
import theme from "../../../theme/Theme";
import commonStyle from "../common.style";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Cookies from "js-cookie";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GiTrophy } from "react-icons/gi";
import { generateReferral } from "../../../utils/referralUtils";
import { Winning } from "../../../assets";

const MyWinningsPage = () => {
  const [winnings, setWinnings] = useState([]);
  const [shareDialog, setShareDialog] = useState(false);
  const [referral, setReferrals] = useState(null);
  // const referral = "http://swss-draw.web.app/"; // The URL you want to share
  const title = "Share Your Big Win!";
  const line1 = "I just won";
  const description =
    "in the SwssDraw blockchain lucky draw! 🎉✨.\n\n It's completely transparent and powered by Blockchain Smart Contracts. Join the excitement and try your luck! Use my referral link to sign up and earn rewards: ";
  const line3 =
    "Don't miss your chance to win big! \n\n#SwssDraw #Blockchain #LuckyDraw #WinBig\n\n";

  const [shareDescription, setShareDescription] = useState(description);

  const getReferrals = async () => {
    try {
      const address = Cookies.get("connectedAddress");
      if (address) {
        const link = generateReferral(address);
        setReferrals(link);
      }
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const fetchWinningsData = async () => {
    try {
      const winningsData = await fetchWinners(); // Fetch winners using utility
      console.log("Fetched Winnings Data:", winningsData);
      setWinnings(winningsData);
    } catch (error) {
      console.error("Error fetching winnings:", error);
    }
  };

  useEffect(() => {
    getReferrals();
    fetchWinningsData();
  }, []);

  const handleShare = (draw, winner) => {
    const drawName = draw.name;
    const prizeAmount = `${draw.currencyTicker} ${formatNumber(
      winner.prizeAmount
    )}`;
    const referralLink = referral ? referral : "";
    const message = `${line1} ${prizeAmount} ${description} ${referralLink}. ${line3}`;
    setShareDescription(message);
    setShareDialog(true);
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DashboardPageTitle title={"My Wins"} />
      </Stack>

      <TableContainer component={Paper} sx={commonStyle.tableBackground}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.custom.tableHeaderBg }}>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                #
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                Draw Id
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "40%" }}>
                Draw Name
              </TableCell>
              <TableCell sx={commonStyle.ticketTitleStyle}>
                Ticket Number
              </TableCell>
              <TableCell sx={commonStyle.ticketTitleStyle}>Win Prize</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {winnings?.length > 0 ? (
              winnings.map((draw, drawIndex) =>
                draw.winnerData.map((winner, winnerIndex) => (
                  <TableRow
                    key={`${draw.drawId}-${winnerIndex}`}
                    sx={{
                      backgroundColor:
                        (drawIndex * draw.winnerData.length + winnerIndex + 1) %
                          2 ===
                        0
                          ? theme.custom.tableRow
                          : theme.custom.white,
                    }}
                  >
                    <TableCell
                      sx={{
                        ...commonStyle.ticketValueTitleStyle,
                        width: "10%",
                      }}
                    >
                      {drawIndex * draw.winnerData.length + winnerIndex + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...commonStyle.ticketValueTitleStyle,
                        width: "10%",
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      }}
                      onClick={() => {
                        window.open(`/draw/${draw.drawId}`, "_blank");
                      }}
                    >
                      {draw.drawId}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...commonStyle.ticketValueTitleStyle,
                        width: "40%",
                      }}
                    >
                      {draw.name}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {Number(winner.ticketNumber)}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {draw.currencyTicker} {formatNumber(winner.prizeAmount)}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleShare(draw, winner)}>
                        <AiOutlineShareAlt />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    ...commonStyle.ticketValueTitleStyle,
                    width: "10%",
                  }}
                >
                  {1}
                </TableCell>
                <TableCell
                  sx={{
                    ...commonStyle.ticketValueTitleStyle,
                    width: "10%",
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                  }}
                  onClick={() => {
                    //window.open(`/draw/${draw.drawId}`, "_blank");
                  }}
                >
                  {"23"}
                </TableCell>
                <TableCell
                  sx={{
                    ...commonStyle.ticketValueTitleStyle,
                    width: "40%",
                  }}
                >
                  {"ABA LKJALd "}
                </TableCell>
                <TableCell sx={commonStyle.ticketValueTitleStyle}>
                  {Number("340068")}
                </TableCell>
                <TableCell sx={commonStyle.ticketValueTitleStyle}>
                  {"USDT"} {formatNumber("340068")}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      handleShare(
                        { name: "My Swss Draw", currencyTicker: "USDT" },
                        { prizeAmount: "45000" }
                      )
                    }
                  >
                    <AiOutlineShareAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
              // <TableRow>
              //   <TableCell colSpan={6} sx={{ textAlign: "center" }}>
              //     <Typography sx={commonStyle.noTransactions}>
              //       No Winnings Data Found
              //     </Typography>
              //   </TableCell>
              // </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={shareDialog} onClose={() => setShareDialog(false)}>
        <DialogTitle
          sx={{
            padding: "0.3em 1em",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <GiTrophy color="#FFD700" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: theme.custom.text,
              }}
            >
              Share Your Big Win!
            </Typography>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* <Box sx={{ position: "absolute", opacity: 0.2, top: -30 }}>
            <WinnerIcon />
          </Box> */}
          <Grid2 container spacing={2}>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <Box component={"img"} src={Winning} />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: theme.custom.text,
                    marginBottom: "1em",
                  }}
                >
                  Congratulations on your amazing win! Spread the excitement and
                  inspire others by sharing your achievement on social media.
                  Let your friends join the fun with your referral link and earn
                  rewards together. The more, the merrier!
                </Typography>
                <Box
                  sx={{
                    padding: "5px 1em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={3}>
                    <EmailShareButton
                      url={referral}
                      subject={title}
                      body={shareDescription}
                    >
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>

                    <WhatsappShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={referral}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <RedditShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={referral}
                    >
                      <RedditIcon size={32} round={true} />
                    </RedditShareButton>

                    {/* <LinkedinShareButton
                      title={title}
                      summary={shareDescription}
                      url={referral}
                    >
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={3}
                    mt={1}
                  >
                    <TwitterShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={referral}
                      hashtags={[
                        "#SwssDraw",
                        "#Blockchain",
                        "#LuckyDraw",
                        "#WinBig",
                      ]}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>

                    <FacebookShareButton
                      url={`${referral}&t=${title}&text=${shareDescription}`}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <TelegramShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={referral}
                    >
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                  </Stack>
                </Box>
              </Box>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                sx={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.custom.white,
                    fontWeight: 600,
                    borderRadius: "4px",
                    padding: "3px 20px",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() => setShareDialog(false)}
                >
                  Close
                </Button>
              </Stack>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MyWinningsPage;
