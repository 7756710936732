import {
  Box,
  Button,
  Divider,
  Grid2,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import { useState, useEffect } from "react";
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  ClockIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { DrawsList } from "../../data/DrawsList";
import dayjs from "dayjs";
import { convertToISO, formatNumber } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { fetchParticipantCount } from "../../utils/drawDetailsUtils";
import theme from "../../theme/Theme";
import { blockchainNetworks } from "../../config/blockchain";

const DrawPage = () => {
  const navigate = useNavigate();
  const [anchorFilterEl, setAnchorFilterEl] = useState(null);
  const [anchorSortEl, setAnchorSortEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const totalPages = Math.ceil(DrawsList.length / itemsPerPage);
  const [soldTickets, setSoldTickets] = useState({});

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Paginated items for the current page
  const paginatedItems = DrawsList.filter(
    (draw) => new Date(draw.endDate) > new Date()
  ).slice(startIndex, endIndex);

  const filterSelected = () => {
    setAnchorFilterEl(null);
    setAnchorSortEl(null);
  };
  const handleFilterClick = (event) => {
    setAnchorFilterEl(event.currentTarget);
  };
  const handleSortClick = (event) => {
    setAnchorSortEl(event.currentTarget);
  };

  const dayRemaining = (date, time) => {
    const inputDate = convertToISO(date, time);
    const targetDate = dayjs(inputDate);
    const today = dayjs();
    const differenceInDays = targetDate.diff(today, "day");
    return differenceInDays;
  };

  const getSoldTicketPercentages = (soldTickets, totalTickets) => {
    const soldPercentages = (soldTickets / totalTickets) * 100;
    return soldPercentages;
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchSoldTickets = async (drawId, blockchain) => {
    try {
      const response = await fetchParticipantCount(drawId, blockchain);
      setSoldTickets((prevState) => ({
        ...prevState,
        [drawId]: response,
      }));
    } catch (error) {
      console.error("Error fetching sold tickets:", error);
    }
  };

  useEffect(() => {
    paginatedItems.forEach((item) => {
      fetchSoldTickets(item.id, item.blockchain);
    });
  }, []);

  const getBlockchainIcon = (blockchain) => {
    return blockchainNetworks[blockchain].iconUrl;
  };

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.bannerImage}>
        <Box
          px={{ xs: 1, sm: 1, md: 4, lg: 4 }}
          py={{ xs: 1, sm: 1, md: 2, lg: 6 }}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography sx={styles.bannerTitle}>Contest List</Typography>
          <Typography sx={styles.bannerHeading}>
            Your Ticket To A Life-Changing Win!
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.pageBackground} />
      <Box sx={styles.pageContent}>
        <Box sx={styles.filterRow}>
          <Typography sx={styles.paginationCount}>
            {`Showing ${currentPage}-${
              DrawsList.length < itemsPerPage
                ? DrawsList.length
                : currentPage * itemsPerPage
            } of ${DrawsList.length} RESULT`}
          </Typography>
          {/* <Stack direction={"row"} spacing={1}>
            <Box>
              <Button
                id="basic-button"
                sx={styles.filterButtonStyle}
                aria-controls={anchorFilterEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorFilterEl ? "true" : undefined}
                onClick={handleFilterClick}
              >
                <AdjustmentsHorizontalIcon width={20} />
                <Typography sx={styles.filterButtonText}>Filter</Typography>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorFilterEl}
                open={anchorFilterEl}
                onClose={filterSelected}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>
                    Days Remaining
                  </Typography>
                </MenuItem>
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>Ticket Price</Typography>
                </MenuItem>
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>
                    Winning probability
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="basic-button"
                sx={styles.filterButtonStyle}
                aria-controls={anchorSortEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorSortEl ? "true" : undefined}
                onClick={handleSortClick}
              >
                <Typography sx={styles.filterButtonText}>
                  Sort by popularity
                </Typography>
                <ChevronDownIcon width={20} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorSortEl}
                open={anchorSortEl}
                onClose={filterSelected}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>Ticket Price</Typography>
                </MenuItem>
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>
                    Winning probability
                  </Typography>
                </MenuItem>
                <MenuItem onClick={filterSelected}>
                  <Typography sx={styles.menuItemText}>
                    Number of winners
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Stack> */}
        </Box>
        <Grid2 container spacing={{ xs: 3, sm: 3, md: 3, lg: 2 }}>
          {paginatedItems.map((item, index) => (
            <Grid2
              item
              size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
              key={index}
              sx={{
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
              onClick={() => {
                navigate(`/draw/${item.id}`);
              }}
            >
              <Box sx={styles.competitionCard}>
                <Box sx={styles.imageContainer}>
                  <img
                    src={item.bannerImage}
                    style={styles.competitionCardImage}
                    alt="epic_win"
                  />
                  <Box sx={styles.titleContainer}>
                    <Typography sx={styles.competitionCardTitle}>
                      {item.title}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={styles.competitionCardContent}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography sx={styles.totalPoolPriceText}>
                      Total Pool Prize <br />
                      <span style={styles.totalPoolPriceTicker}>
                        {item.currencyTicker}{" "}
                      </span>
                      <span style={styles.totalPoolPriceValueText}>
                        {formatNumber(item.totalPrice)}
                      </span>
                    </Typography>
                    <img
                      src={getBlockchainIcon(item.blockchain)}
                      alt={item.blockchain}
                      width={24}
                      height={24}
                      style={styles.blockchainIcon}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                    mt={{ xs: 1, md: 1 }}
                    mx={3}
                  >
                    <Box>
                      <Typography sx={styles.otherTitleText}>
                        1st Winner
                      </Typography>
                      <Typography sx={styles.otherPriceValueText}>
                        {item.currencyTicker} {formatNumber(item.firstWinner)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={styles.otherTitleText}>
                        Total Winners
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.otherPriceValueText,
                          textAlign: "right",
                        }}
                      >
                        {item.totalWinners}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    mt={{ xs: 1.5, md: 3 }}
                    mx={3}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <ClockIcon width={24} />
                      <Typography sx={styles.daysText}>
                        {`${dayRemaining(
                          item.endDate,
                          item.endTime
                        )} Days Remaining`}
                      </Typography>
                    </Stack>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "rgba(82, 82, 82, 1)" }}
                    />
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <TrophyIcon width={24} />
                      <Typography sx={styles.daysText}>
                        {`${item.winingProbability} Winning Probability`}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Box mx={3} my={{ xs: 1.5, md: 3 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mb={0.3}
                    >
                      <Typography sx={styles.progressText}>
                        {`Sold: ${soldTickets[item.id] || 0}`}
                      </Typography>
                      <Typography sx={styles.progressText}>
                        {`Remaining: ${formatNumber(
                          item.totalTickets - (soldTickets[item.id] || 0)
                        )}`}
                      </Typography>
                    </Stack>
                    <Box sx={styles.progressBarContainer}>
                      <Box
                        sx={{
                          ...styles.soldTickers,
                          width: `${getSoldTicketPercentages(
                            soldTickets[item.id] || 0,
                            item.totalTickets
                          )}%`,
                        }}
                      ></Box>
                    </Box>
                  </Box>

                  <Box sx={styles.pricePerTicket}>
                    <Typography sx={styles.joinButtonText}>
                      Price / Ticket{" "}
                      <span style={{ fontWeight: 700 }}>
                        {item.currencyTicker} {item.pricePerTicket}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid2>
          ))}
        </Grid2>
        <Box sx={styles.paginationContainer}>
          <Pagination
            count={totalPages}
            shape="rounded"
            size="large"
            sx={styles.pagination}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  backgroundColor: item.selected
                    ? theme.custom.white
                    : theme.palette.primary.main,
                  borderColor: item.selected
                    ? theme.palette.primary.main
                    : theme.custom.white,
                  borderWidth: item.selected ? "2px" : "1px",
                  borderRadius: "12px",
                  color: item.selected
                    ? theme.palette.primary.main
                    : theme.custom.white,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                  ...(item.type === "previous" || item.type === "next"
                    ? {
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.custom.white,
                        },
                      }
                    : {}),
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default DrawPage;
