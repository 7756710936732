import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(191, 20, 34, 1)",
      dark: "rgba(123,14,23,1)",
      light: "#fd7a7f",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#FBFBFF",
    },
    text: {
      primary: "#0f172a",
      secondary: "#656e7a",
      disabled: "#a9a9a9",
    },
  },
  custom: {
    white: "#ffffff",
    black: "#000000",
    primaryLighter: "#fde9eb",
    primaryDarker: "#4f060c",
    link: "#0e6ba8",
    adornment: "#95A3B3",
    emperor: "#525252",
    tableHeaderBg: "rgb(249,250,251)",
    tableRow: "rgb(249,250,251)",
    tableBorder: "rgb(228,229,231)",
  },
  typography: {
    fontFamily: '"Proxima Nova", sans-serif',
    h1: {
      fontSize: "2.2rem", // Custom heading style
      fontWeight: "700",
    },
    h2: {
      fontSize: "2.1rem", // Custom heading style
      fontWeight: "700",
    },
    h3: {
      fontSize: "2rem", // Custom heading style
      fontWeight: "700",
    },
    h4: {
      fontSize: "1.9rem", // Custom heading style
      fontWeight: "700",
    },
    h5: {
      fontSize: "1.8rem", // Custom heading style
      fontWeight: "700",
    },
    h6: {
      fontSize: "1.7rem", // Custom heading style
      fontWeight: "700",
    },
    body1: {
      fontSize: "1.6rem", // Custom body style
      fontWeight: "400",
    },
    body2: {
      fontSize: "1.5rem", // Custom body style
      fontWeight: "400",
    },
  },
  shadows: Array(25).fill("none"),
  components: {
    // You can override default MUI component styles globally here
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Custom button border radius
          textTransform: "none", // Disable uppercase text
        },
      },
    },
  },
});
export default theme;
