import { Box, Card, CardContent, Typography } from "@mui/material";
import ComingSoonIcon from "../assets/svg/ComingSoonIcon";
import theme from "../theme/Theme";

const ComingSoonCard = ({ title }) => {
  return (
    <Box p={{ xs: 1, md: 6, lg: 8 }}>
      <Card
        sx={{
          position: "relative",
          boxShadow:
            "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px",
        }}
      >
        <Box sx={{ position: "absolute", top: 0, right: -3 }}>
          <ComingSoonIcon />
        </Box>
        <CardContent sx={styles.cardContentStyle}>
          <Typography sx={styles.titleStyle}>{title}</Typography>
          <Box sx={styles.describeBoxStyle}>
            <Typography sx={styles.describeTextStyle}>Stay Tuned</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default ComingSoonCard;

const styles = {
  cardContentStyle: {
    padding: "4em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleStyle: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: { xs: "6vw", lg: "5vw" },
    mb: 2,
    textTransform: "uppercase",
  },
  describeBoxStyle: {
    background: theme.palette.primary.main,
    borderTopLeftRadius: "1em",
    borderBottomRightRadius: "1em",
    marginBottom: "3em",
  },
  describeTextStyle: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: { xs: "3vw", lg: "2vw" },
    padding: "0.3em 1em",
  },
};
