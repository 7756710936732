// WalletDisplay.js
import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import WalletInfo from "./WalletInfo";
import theme from "../theme/Theme";

const WalletDisplay = ({
  connectedAddress,
  connectedWalletType,
  onConnect,
  onDisconnect,
}) => {
  return useMemo(
    () =>
      connectedAddress ? (
        <WalletInfo
          connectedWalletType={connectedWalletType}
          connectedAddress={connectedAddress}
          onDisconnect={onDisconnect}
        />
      ) : (
        <Box sx={styles.buttonWrapper} onClick={onConnect}>
          <Box sx={styles.buttonContainer}>
            <Typography sx={styles.buttonText}>Connect Wallet</Typography>
          </Box>
        </Box>
      ),
    [connectedAddress, connectedWalletType]
  );
};

const styles = {
  buttonWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: "0.15em",
    cursor: "pointer",
  },
  buttonContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    padding: "0.3em 1em",
    borderRadius: "10px",
  },
  buttonText: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
  },
};

export default WalletDisplay;
