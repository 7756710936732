const OrderApproved = ({
  width = 32,
  height = 32,
  color2 = "#b91321",
  color1 = "#ff8f8f",
}) => {
  return (
    <svg viewBox="0 0 32 32" width={width} height={height}>
      <path
        fill={color1}
        d="M10,1h6a0,0,0,0,1,0,0V6.13a.87.87,0,0,1-.87.87H10.87A.87.87,0,0,1,10,6.13V1A0,0,0,0,1,10,1Z"
      />
      <path
        fill={color2}
        d="M11,26H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H23a3,3,0,0,1,3,3v8a1,1,0,0,1-2,0V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3V23a1,1,0,0,0,1,1h8a1,1,0,0,1,0,2Z"
      />
      <path fill={color2} d="M7,22H5a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" />
      <path
        fill={color2}
        d="M23,32a9,9,0,1,1,9-9A9,9,0,0,1,23,32Zm0-16a7,7,0,1,0,7,7A7,7,0,0,0,23,16Z"
      />
      <circle fill={color1} cx="23" cy="23" r="5" />
      <path
        fill={color2}
        d="M23,26a1,1,0,0,1-.71-.29l-2-2a1,1,0,0,1,1.42-1.42L23,23.59l3.29-3.3a1,1,0,0,1,1.42,1.42l-4,4A1,1,0,0,1,23,26Z"
      />
    </svg>
  );
};
export default OrderApproved;
