const draHelperABI = 
[
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "initialOwner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_createDraw",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "currentTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "endTime",
				"type": "uint256"
			}
		],
		"name": "DrawNotEnded",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "maxAllowed",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "provided",
				"type": "uint256"
			}
		],
		"name": "ExceedsMaxReferralLevels",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "maxAllowed",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "provided",
				"type": "uint256"
			}
		],
		"name": "ExceedsMaxWinnerLevels",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "totalReward",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "remainingPool",
				"type": "uint256"
			}
		],
		"name": "MaxRewardExceedsPoolSize",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "rewardAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "ticketPrice",
				"type": "uint256"
			}
		],
		"name": "RewardLessThanTicketPrice",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "totalReward",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "remainingPool",
				"type": "uint256"
			}
		],
		"name": "TotalRewardExceedsPoolSize",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "currentStatus",
				"type": "uint256"
			}
		],
		"name": "WinnerCountNotDefined",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			}
		],
		"name": "WinnersAlreadyPicked",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "totalWinners",
				"type": "uint256"
			}
		],
		"name": "CountOfWinnersDefined",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "invalidTicketNumber",
				"type": "uint256"
			}
		],
		"name": "ErrorInvalidTicket",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "invalidTokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketNumber",
				"type": "uint256"
			}
		],
		"name": "ErrorInvalidToken",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "levelIndex",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "level",
				"type": "uint256"
			}
		],
		"name": "NoWinnersForLevel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "level",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "prizeAmount",
				"type": "uint256"
			}
		],
		"name": "PrizeAmountCalculated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "winningTicketNumber",
				"type": "uint256"
			}
		],
		"name": "RandomWinnerSelected",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "level",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winnerAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketNumber",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "prizeAmount",
				"type": "uint256"
			}
		],
		"name": "WinnerSelected",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "_objDraw",
		"outputs": [
			{
				"internalType": "contract SwssSmartDraw",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "totalTicketsSold",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "ticketPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "ownerFee",
				"type": "uint256"
			},
			{
				"internalType": "uint16",
				"name": "creatorFee",
				"type": "uint16"
			},
			{
				"internalType": "uint32[]",
				"name": "referralPercentages",
				"type": "uint32[]"
			}
		],
		"name": "calculateRemainingPool",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			}
		],
		"name": "countNumberOfWinners",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "generateRandomStartNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "level",
				"type": "uint256"
			}
		],
		"name": "getReferrer",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "drawId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "batchSize",
				"type": "uint256"
			}
		],
		"name": "pickWinner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "totalTicketPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "maxReferralLevels",
				"type": "uint256"
			},
			{
				"components": [
					{
						"internalType": "string",
						"name": "drawName",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "ticketPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "startTime",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "endTime",
						"type": "uint256"
					},
					{
						"internalType": "uint32",
						"name": "maxParticipants",
						"type": "uint32"
					},
					{
						"internalType": "address",
						"name": "creator",
						"type": "address"
					},
					{
						"internalType": "uint16",
						"name": "creatorFee",
						"type": "uint16"
					},
					{
						"internalType": "address",
						"name": "tokenAddress",
						"type": "address"
					},
					{
						"components": [
							{
								"internalType": "uint8",
								"name": "level",
								"type": "uint8"
							},
							{
								"internalType": "uint16",
								"name": "percentage",
								"type": "uint16"
							},
							{
								"internalType": "uint256",
								"name": "maxReward",
								"type": "uint256"
							},
							{
								"internalType": "uint32",
								"name": "winnerCount",
								"type": "uint32"
							}
						],
						"internalType": "struct SwssSmartDraw.WinnerConfig[]",
						"name": "winnerLevels",
						"type": "tuple[]"
					},
					{
						"internalType": "uint32[]",
						"name": "referralPercentages",
						"type": "uint32[]"
					},
					{
						"internalType": "uint8",
						"name": "status",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "randomStartNumber",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "recipient",
								"type": "address"
							},
							{
								"internalType": "uint16",
								"name": "percentage",
								"type": "uint16"
							}
						],
						"internalType": "struct SwssSmartDraw.WithdrawalFee",
						"name": "withdrawalFee",
						"type": "tuple"
					}
				],
				"internalType": "struct SwssSmartDraw.DrawConfig",
				"name": "draw",
				"type": "tuple"
			},
			{
				"internalType": "address",
				"name": "referrer",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "functionExecutionFeeRecipient",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "infrastructureMaintenanceFeeRecipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "functionExecutionFee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "maintenanceFee",
				"type": "uint256"
			}
		],
		"name": "prepareFeeAndRecipientArrays",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "recipients",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "amounts",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_ticketPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_maxParticipants",
				"type": "uint32"
			},
			{
				"internalType": "uint16",
				"name": "_creatorFee",
				"type": "uint16"
			},
			{
				"components": [
					{
						"internalType": "uint8",
						"name": "level",
						"type": "uint8"
					},
					{
						"internalType": "uint16",
						"name": "percentage",
						"type": "uint16"
					},
					{
						"internalType": "uint256",
						"name": "maxReward",
						"type": "uint256"
					},
					{
						"internalType": "uint32",
						"name": "winnerCount",
						"type": "uint32"
					}
				],
				"internalType": "struct SwssSmartDraw.WinnerConfig[]",
				"name": "_winnerLevels",
				"type": "tuple[]"
			},
			{
				"internalType": "uint32[]",
				"name": "_referralPercentages",
				"type": "uint32[]"
			},
			{
				"internalType": "uint256",
				"name": "totalFeePercent",
				"type": "uint256"
			}
		],
		"name": "validateDraw",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	}
]
export default draHelperABI;