import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import Celebration from "../../../assets/svg/Celebration";

const RecentWinners = () => {
  return (
    <Box>
      <section style={{ position: "relative" }}>
        <Box sx={styles.celebrationImage1}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage2}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage3}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage4}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage5}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationBg}>
          <Typography sx={styles.titleText}>
            RECENT <span style={styles.titleHighLight}>WINNERS</span>
          </Typography>
          <Typography sx={{ ...styles.faqBoxTitle, textAlign: 'center', paddingTop: '15px' }}>
            Winners are on their way! Stay tuned as we prepare for our first exciting draw – the future of SwssDraw is just beginning!
          </Typography>
          {/* <TableContainer component={Paper} sx={styles.tableContainerStyle}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeadCell}>CONTEST NO.</TableCell>
                  <TableCell sx={styles.tableHeadCell}>WINNERS</TableCell>
                  <TableCell sx={styles.tableHeadCell}>
                    WINNING NUMBERS
                  </TableCell>
                  <TableCell sx={styles.tableHeadCell}>TIME</TableCell>
                  <TableCell sx={styles.tableHeadCell}>REWARDS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                  <TableCell sx={styles.tableBodyCell}>L043</TableCell>
                  <TableCell sx={styles.tableBodyCell}>Carlos deslno</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>2</Typography>
                      <Typography sx={styles.winnerTickerNo}>4</Typography>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>5</Typography>
                      <Typography sx={styles.winnerTickerNo}>8</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    15 May 2024 8.30 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Car</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tableBodyCell}>L203</TableCell>
                  <TableCell sx={styles.tableBodyCell}>Marianne Beat</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>2</Typography>
                      <Typography sx={styles.winnerTickerNo}>3</Typography>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>7</Typography>
                      <Typography sx={styles.winnerTickerNo}>8</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    18 Sep 2024 01.30 PM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Scooter</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                  <TableCell sx={styles.tableBodyCell}>L423</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    Gerolf Felizitas
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>2</Typography>
                      <Typography sx={styles.winnerTickerNo}>4</Typography>
                      <Typography sx={styles.winnerTickerNo}>6</Typography>
                      <Typography sx={styles.winnerTickerNo}>8</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    22 Feb 2024 10.30 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Bike</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tableBodyCell}>L143</TableCell>
                  <TableCell sx={styles.tableBodyCell}>Ingo Kasimir</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>6</Typography>
                      <Typography sx={styles.winnerTickerNo}>2</Typography>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>4</Typography>
                      <Typography sx={styles.winnerTickerNo}>3</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    15 May 2024 8.30 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Watch</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                  <TableCell sx={styles.tableBodyCell}>P143</TableCell>
                  <TableCell sx={styles.tableBodyCell}>Brigitta Time</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>4</Typography>
                      <Typography sx={styles.winnerTickerNo}>3</Typography>
                      <Typography sx={styles.winnerTickerNo}>5</Typography>
                      <Typography sx={styles.winnerTickerNo}>8</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    15 Jan 2024 11.00 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Car</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                  <TableCell sx={styles.tableBodyCell}>P038</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    Metztli Nikola
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>3</Typography>
                      <Typography sx={styles.winnerTickerNo}>4</Typography>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>5</Typography>
                      <Typography sx={styles.winnerTickerNo}>8</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    11 May 2024 8.30 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>House</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                  <TableCell sx={styles.tableBodyCell}>L043</TableCell>
                  <TableCell sx={styles.tableBodyCell}>Tim Cook</TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    <Stack direction={"row"} spacing={0.4}>
                      <Typography sx={styles.winnerTickerNo}>3</Typography>
                      <Typography sx={styles.winnerTickerNo}>1</Typography>
                      <Typography sx={styles.winnerTickerNo}>2</Typography>
                      <Typography sx={styles.winnerTickerNo}>5</Typography>
                      <Typography sx={styles.winnerTickerNo}>7</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    12 Apr 2024 10.30 AM
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>Cash</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </Box>
      </section>
    </Box>
  );
};
export default RecentWinners;
