const NewTabIcon = ({ width = 18, height = 18, color = "#005795" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none">
      <path
        d="M12.1875 13.0168H2.8125V3.64185H7.5V2.70435H2.8125C2.56386 2.70435 2.3254 2.80312 2.14959 2.97893C1.97377 3.15475 1.875 3.39321 1.875 3.64185V13.0168C1.875 13.2655 1.97377 13.5039 2.14959 13.6798C2.3254 13.8556 2.56386 13.9543 2.8125 13.9543H12.1875C12.4361 13.9543 12.6746 13.8556 12.8504 13.6798C13.0262 13.5039 13.125 13.2655 13.125 13.0168V8.32935H12.1875V13.0168Z"
        fill={color}
      />
      <path
        d="M12.1875 13.0168H2.8125V3.64185H7.5V2.70435H2.8125C2.56386 2.70435 2.3254 2.80312 2.14959 2.97893C1.97377 3.15475 1.875 3.39321 1.875 3.64185V13.0168C1.875 13.2655 1.97377 13.5039 2.14959 13.6798C2.3254 13.8556 2.56386 13.9543 2.8125 13.9543H12.1875C12.4361 13.9543 12.6746 13.8556 12.8504 13.6798C13.0262 13.5039 13.125 13.2655 13.125 13.0168V8.32935H12.1875V13.0168Z"
        fill={color}
      />
      <path
        d="M12.1875 3.64185V1.76685H11.25V3.64185H9.375V4.57935H11.25V6.45435H12.1875V4.57935H14.0625V3.64185H12.1875Z"
        fill={color}
      />
    </svg>
  );
};
export default NewTabIcon;
